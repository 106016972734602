import React from 'react';
import { setSuccessMessage } from "redux/actions/global-actions";
import { setErrorMessage } from "redux/actions/global-actions";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

const alertStyling = {
    position: "fixed",
    bottom: "10%",
    right: 0,
    left: 0,
    maxWidth: "60%",
    margin: "0 auto",
    zIndex: 9000
}

// const containerStyling = {
//     position: "fixed",
//     zIndex: 100,
//     width: "100%",
//     bottom: "10%"
// }

function SuccessErrorMessage({ successMessage, errorMessage, setSuccessMessage, setErrorMessage }) {
    if (successMessage.length > 0 || errorMessage.length > 0) {
        setTimeout(function () {
            setSuccessMessage("");
            setErrorMessage("");
        }, 3000);
    }

    if (successMessage.length > 0)
        return <Alert style={alertStyling} color="success"><strong>{successMessage}</strong></Alert>;
    else if (errorMessage.length > 0)
        return <Alert style={alertStyling} color="danger"><strong>{errorMessage}</strong></Alert>;
    return null;
}

const stateToProps = state => {
    return {
        successMessage: state.global.successMessage,
        errorMessage: state.global.errorMessage
    }
}

const dispatchToProps = dispatch => {
    return {
        setSuccessMessage: msg => dispatch(setSuccessMessage(msg)),
        setErrorMessage: msg => dispatch(setErrorMessage(msg))
    }
}

export default connect(stateToProps, dispatchToProps)(SuccessErrorMessage);