import React from 'react';
import { Row, Col } from "reactstrap";
import SettingsSettings from './Settings-settings';
import { withTranslation } from 'react-i18next';
import BankSettings from './CharityBankDetails';
import InfoWithTooltip from 'components/Small/InfoWithTooltip';

function CharitySettings({ onChange, settings, t }) {

    return (<>
        <SettingsSettings settings={settings} onChange={onChange} />
        <hr className="my-4" />
        <h6 className="heading-small text-muted mb-4">
            {t("Charity Settings")}
        </h6>
        <div className="pl-lg-4">
            <Row>
                {/* V1 will not have these 2 features implemented */}
                {/* <Col lg="12">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="generate_receipts"
                            type="checkbox"
                            checked={settings.generate_receipts}
                            name="generate_receipts"
                            onChange={onChange}
                        />
                        <label className="custom-control-label" htmlFor="generate_receipts">{t("Generation of Donation Receipts")}</label>
                    </div>
                </Col>
                <Col lg="12">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="ereceipt_generation_consent"
                            type="checkbox"
                            checked={settings.ereceipt_generation_consent}
                            name="ereceipt_generation_consent"
                            onChange={onChange}
                        />
                        <label className="custom-control-label" htmlFor="ereceipt_generation_consent">{t("Generate E-receipts")}</label>
                    </div>
                </Col> */}
                <Col lg="6">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="auto_comm_subtract"
                            checked={settings.auto_comm_subtract}
                            name="auto_comm_subtract"
                            type="checkbox"
                            onChange={onChange} />
                        <label
                            className="custom-control-label"
                            htmlFor="auto_comm_subtract">
                            {t("Automatically subtract the commission")}</label><InfoWithTooltip id="auto_comm_subtr" text={t("Commission subtraction explanation")} />
                    </div>
                </Col>
            </Row>
            <hr className="my-4" />
            <BankSettings bank_account={{}} settings={settings} t={t} onChange={onChange} />
            <hr className="my-4" />
        </div>
    </>);
}
export default withTranslation()(CharitySettings);