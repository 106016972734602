import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';

function AccountSettings({ t, bank_account, onChange }) {

    return bank_account ? (<>
        <FormGroup className="mb-3">
            <label
                className="form-control-label"
                htmlFor="accountholder_name">
                {t("Account holder name")}
            </label>
            <Input
                className="form-control-alternative"
                id="accountholder_name"
                placeholder={t("Account holder name")}
                name="accountholder_name"
                onChange={onChange}
                value={bank_account.accountholder_name}
                type="text"
            />
        </FormGroup>
        <FormGroup className="mb-3">
            <label
                className="form-control-label"
                htmlFor="accountholder_address">
                {t("Address")}
            </label>
            <Input
                className="form-control-alternative"
                id="accountholder_address"
                placeholder={t("Address")}
                name="accountholder_address"
                onChange={onChange}
                value={bank_account.accountholder_address}
                type="text"
            />
        </FormGroup>
        <FormGroup className="mb-3">
            <label
                className="form-control-label"
                htmlFor="accountholder_postcode">
                {t("Postal code")}
            </label>
            <Input
                className="form-control-alternative"
                id="accountholder_postcode"
                placeholder={t("Postal code")}
                name="accountholder_postcode"
                onChange={onChange}
                value={bank_account.accountholder_postcode}
                type="text"
            />
        </FormGroup>
        <FormGroup className="mb-3">
            <label
                className="form-control-label"
                htmlFor="accountholder_city">
                {t("City")}
            </label>
            <Input
                className="form-control-alternative"
                id="accountholder_city"
                placeholder={t("City")}
                name="accountholder_city"
                onChange={onChange}
                value={bank_account.accountholder_city}
                type="text"
            />
        </FormGroup>
        <FormGroup className="mb-3">
            <label
                className="form-control-label"
                htmlFor="accountholder_country">
                {t("Country")}
            </label>
            <Input
                className="form-control-alternative"
                id="accountholder_country"
                placeholder={t("Country")}
                name="accountholder_country"
                onChange={onChange}
                disabled
                value={bank_account.accountholder_country}
                type="text"
            />
        </FormGroup>
        <FormGroup className="mb-3">
            <label
                className="form-control-label"
                htmlFor="name">
                {t("Bank name")}
            </label>
            <Input
                className="form-control-alternative"
                id="bank_name"
                placeholder={t("Bank name")}
                name="bank_name"
                onChange={onChange}
                value={bank_account.bank_name}
                type="text"
            />
        </FormGroup>
        <FormGroup>
            <label
                className="form-control-label"
                htmlFor="name">
                {t("IBAN")}
            </label>
            <Input
                className="form-control-alternative"
                id="iban"
                placeholder={t("IBAN")}
                name="iban"
                onChange={onChange}
                value={bank_account.iban}
                type="text"
            />
        </FormGroup>
        <FormGroup>
            <label
                className="form-control-label"
                htmlFor="name">
                {t("BIC")}
            </label>
            <Input
                className="form-control-alternative"
                id="bic"
                placeholder={t("bic")}
                name="bic"
                onChange={onChange}
                value={bank_account.bic}
                type="text"
            />
        </FormGroup>
    </>)
        : <>
            <h3>{t("Error occured, account not found")}</h3>
        </>;
}

export default withTranslation()(AccountSettings);