/**
* Use the CSS tab above to style your Element's container.
*/
import React, { useEffect, useState } from 'react';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import 'assets/css/IbanFormStyles.css'
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';

const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    placeholderCountry: 'DE',
};

export default function StripeIbanForm({ account, setupIntent, getSetupIntent, t, setShowData, setErrorMessage, setSuccessMessage }) {
    const stripe = useStripe();
    const [setupPending, setSetupPending] = useState(false);
    const [holderName, setHolderName] = useState("");
    const [email, setEmail] = useState("");
    const [last4, setLast4] = useState("");
    const [ibanError, setIbanError] = useState(" ");
    const [disabled, setDisabled] = useState(!stripe);
    const [changeExisting, setChangeExisting] = useState(false);
    const elements = useElements();

    useEffect(() => {
        if (account) {
            setHolderName(account.accountholder_name);
            setEmail(account.accountholder_email);
            setLast4(account.last4);
        }
    }, [account])

    useEffect(() => {
        if (ibanError.length > 0)
            setDisabled(true);
        else
            setDisabled(false);
    }, [ibanError])

    useEffect(() => {
        async function effect() {
            if (!setupIntent)
                return;
            if (setupPending) {
                setSetupPending(false);
                const iban = elements.getElement(IbanElement);
                console.log(iban);
                const result = await stripe.confirmSepaDebitSetup(setupIntent, {
                    payment_method: {
                        sepa_debit: iban,
                        billing_details: {
                            name: holderName,
                            email: email
                        }
                    }
                });
                if (result.error) {
                    setShowData(false)
                    setErrorMessage(result.error.message);
                } else {
                    setShowData(false)
                    setSuccessMessage(t("Bank account successfully updated."));
                }
            }
        }
        effect();
    }, [setupIntent, elements, email, holderName, setErrorMessage, setShowData, setSuccessMessage, t, stripe, setupPending])

    const onChange = (evt) => {
        if (evt.target.name === "email")
            setEmail(evt.target.value);
        else
            setHolderName(evt.target.value);
    }

    const onSubmit = async evt => {
        evt.preventDefault();
        evt.stopPropagation();
        if (!stripe || !elements || ibanError.length > 0) {
            return;
        }
        await getSetupIntent();
        setSetupPending(true);
    }

    return (
        <form onSubmit={onSubmit} >
            <FormGroup className="mb-3">
                <label
                    className="form-control-label"
                    htmlFor="accountholder_name">
                    {t("Account holder name")}
                </label>
                <Input
                    className="form-control-alternative"
                    id="accountholder_name"
                    placeholder={t("Account holder name")}
                    name="accountholder-name"
                    onChange={onChange}
                    value={holderName}
                    type="text"
                    required
                />
            </FormGroup>
            <FormGroup className="mb-3">
                <label
                    className="form-control-label"
                    htmlFor="accountholder_name">
                    {t("E-mail address")}
                </label>
                <Input
                    className="form-control-alternative"
                    id="email"
                    placeholder={t("E-mail address")}
                    name="email"
                    onChange={onChange}
                    value={email}
                    type="email"
                    required
                />
            </FormGroup>

            {/* < div className="form-row" > */}
            {/* {(last4 && last4.length > 0) ? <small>{t("The last 4 digits of saved account")}: {last4}. {t("To overwrite, fill below")}</small> : ''} */}
            <FormGroup className="mb-3">
                <label
                    className="form-control-label">
                    {t("IBAN")}
                </label>
                {last4 && !changeExisting && last4.length > 0 ?
                    <Input
                        className="form-control-alternative"
                        id="iban"
                        placeholder={t("IBAN")}
                        name="iban"
                        onChange={onChange}
                        value={"****** **** **** **** " + last4}
                        type="text"
                        onClick={() => { setChangeExisting(true); }}
                    />
                    :
                    < IbanElement onChange={evt => { setIbanError(evt.error ? evt.error.message : '') }} options={IBAN_ELEMENT_OPTIONS} />
                }
                {ibanError && ibanError.length > 0 ? <small style={{ color: 'red' }}>{ibanError}</small> : ''}
            </FormGroup>
            <div className="text-center">
                <Button
                    className="my-4"
                    color="success"
                    type="submit"
                    disabled={disabled}
                >{t("Set up SEPA Direct Debit")}</Button>
                <Button
                    className="my-4"
                    color="secondary"
                    type="button"
                    onClick={() => setShowData(false)}
                >{t("Cancel")}</Button>
            </div>

            {/* Display mandate acceptance text. */}
            <div className="mandate-acceptance" >
                <small>
                    {t("By providing your payment information and confirming this payment, you authorise(A) Rest - Cent Systems GmbH and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions.As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank.A refund must be claimed within 8 weeks starting from the date on which your account was debited.Your rights are explained in a statement that you can obtain from your bank.You agree to receive notifications for future debits up to 2 days before they occur.")}
                </small>
            </div>
        </form>);
};