/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import { connect } from "react-redux";
import { logOut } from "redux/actions/authentication-actions";
import profileImage from 'assets/img/theme/team-4-800x800.jpg';
import { withTranslation } from 'react-i18next';
import { compose } from "redux";

class DashboardNavbar extends React.Component {

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        style={{width: '100%', height: '100%', borderRadius: "50%"}}
                        alt="..."
                        src={this.props.account.logo_url || profileImage}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.t('My account')}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{this.props.t('Welcome!')}</h6>
                  </DropdownItem>
                  {/* <DropdownItem to="/dashboard/index" tag={Link}>
                    <i className="ni ni-tv-2" />
                    {this.props.t('Dashboard')}
                  </DropdownItem> */}
                  <DropdownItem to="/dashboard/user-profile" tag={Link}>
                    <i className="ni ni-shop" />
                    {this.props.t('Profile')}
                  </DropdownItem>
                  <DropdownItem to="/dashboard/user-settings" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    {this.props.t('Settings')}
                  </DropdownItem>
                  {localStorage.getItem("accType") !== "CHARITY" ?
                    <DropdownItem to="/dashboard/donations" tag={Link}>
                      <i className="ni ni-bullet-list-67" />
                      <span>{this.props.t("Donations")}</span>
                    </DropdownItem>
                    : ""}
                  {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem> */}
                  {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem href="#logout" onClick={e => { e.preventDefault(); this.props.logOut(); }}>
                    <i className="ni ni-user-run" />
                    {this.props.t('Sign out')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.dashboard.account
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logOut()),
  }
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(DashboardNavbar);
