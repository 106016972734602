import { prepareQueryUrl } from 'util/functions';
import axios from './axios-client';

// Sign the user in 
export const apiFetchDonations = async (payload) => {
    const result = await axios.get("donations" + (prepareQueryUrl(payload.queryObj) || ''));
    return result.data;
}

// export donations data as CSV
export const apiExportDonationAsCSV = async (payload) => {
    const {from_date, to_date, status} = payload;
    const result = await axios.post(`donations/csv`, { 
        from_date: from_date,
        to_date: to_date,
        status: status
    } );
    return result.data;
}