/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import store from './redux/store';
import './i18n.js';

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/vendor/select2/dist/css/select2.min.css";

import DashboardLayout from "layouts/Dashboard.js";
import AuthLayout from "layouts/Auth.js";
import { Provider } from "react-redux";
import ProtectedRoute from "components/General/auth-components/ProtectedRoute";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch >
        <Route path="/auth" render={props => <AuthLayout {...props} />} />
        <ProtectedRoute path="/dashboard" component={props => <DashboardLayout {...props} />} />
        {/* </DashboardLayout>render={props => <DashboardLayout {...props} />} /> */}
        {/* <Route path="/dashboard" render={props => <DashboardLayout {...props} />} /> */}
        <Redirect from="/" to="/dashboard/user-profile" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);


const freshdesk_script1 = document.createElement("script");
const freshdesk_script2 = document.createElement("script");
freshdesk_script2.type = 'text/javascript';
freshdesk_script2.src = 'https://euc-widget.freshworks.com/widgets/80000001328.js';
freshdesk_script2.async = true;
freshdesk_script2.defer = true;
freshdesk_script1.innerHTML = "window.fwSettings={'widget_id':80000001328};	!function(){if(\"function\"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()"
document.head.append(freshdesk_script1);
document.head.append(freshdesk_script2);