import React from 'react';
// import { Row, Col, FormGroup, Input, UncontrolledTooltip } from "reactstrap";
import DonatorProfile from './Donator-profile';
import { withTranslation } from 'react-i18next';
// import ProfileImage from './ProfileImage';
// import InfoWithTooltip from 'components/Small/InfoWithTooltip';
// import Button from 'reactstrap/lib/Button';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';

function CompanyProfile({ account, onChange, settings, t, handleFileUpload, categories, platforms }) {
    const [copiedText, setCopiedText] = useState("");

    return (<>
        <DonatorProfile entity={account} onChange={onChange} />
        <hr className="my-4" />
        {/* Description */}
        {/* <h6 className="heading-small text-muted mb-4">{t("Private area")}</h6>
        <div className="pl-lg-4">
            <Row>
                <Col lg="12">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="public"
                            type="checkbox"
                            checked={account.public}
                            name="public"
                            onChange={onChange}
                        />
                        <label className="custom-control-label" htmlFor="public">{t("Public profile")}</label><InfoWithTooltip id="public_profile_info" text={t("Public profile explanation")} />
                    </div>
                </Col>
            </Row>
        </div> */}
        </> );
}

export default withTranslation()(CompanyProfile);