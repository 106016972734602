import React from 'react';
import { Row, Col, FormGroup, Input, UncontrolledTooltip } from "reactstrap";
import DonatorProfile from './Donator-profile';
import { withTranslation } from 'react-i18next';
import ProfileImage from './ProfileImage';
import InfoWithTooltip from 'components/Small/InfoWithTooltip';
import Button from 'reactstrap/lib/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';

function ShopProfile({ account, onChange, settings, t, handleFileUpload, categories, platforms }) {
    const [copiedText, setCopiedText] = useState("");

    return (<>
        <DonatorProfile entity={account} onChange={onChange} />
        <hr className="my-4" />
        {/* Description */}
        <h6 className="heading-small text-muted mb-4">{t("Private area")}</h6>
        <div className="pl-lg-4">
            <Row>
                <Col lg="12">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="public"
                            type="checkbox"
                            checked={account.public}
                            name="public"
                            onChange={onChange}
                        />
                        <label className="custom-control-label" htmlFor="public">{t("Public profile")}</label><InfoWithTooltip id="public_profile_info" text={t("Public profile explanation")} />
                    </div>
                </Col>
            </Row>
        </div>
        <hr className="my-4" />
        <h6 className="heading-small text-muted mb-4">{t("About the shop")}</h6>
        <div className="pl-lg-4">
            <Row>
                <Col lg="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-platform">
                            {t("E-Commerce Platform")}
                        </label><InfoWithTooltip id="ecommerce_platform_info" text={t("Please select the platform your Online Shop is based on")} />
                        <Input
                            className="form-control-alternative"
                            id="input-platform"
                            placeholder={t("E-Commerce Platform")}
                            name="platform"
                            onChange={onChange}
                            value={account.platform}
                            type="select">
                            {platforms.map(c =>
                                <option key={c.key} value={c.key}>{c.value}</option>
                            )}
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-shop-id">
                            {t("Shop ID")}
                        </label><InfoWithTooltip id="shop_id_info" text={t("This is the Shop ID that you need to use in the plugin configuration on your shop platform.")} />
                        <CopyToClipboard
                            text={account.id}
                            onCopy={() => setCopiedText(account.id)}>
                            <Button
                                // outline
                                className="btn-icon-clipboard copyToClipboardButton"
                                id="tooltip982655500"
                                type="button">
                                <div>
                                    <i className="fas fa-copy" />
                                    <span>{account.id}</span>
                                </div>
                            </Button>
                        </CopyToClipboard>
                        <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="tooltip982655500">
                            {copiedText === account.id ? t("Copied") : t("Copy to clipboard")}
                        </UncontrolledTooltip>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-last-name">
                            {t("Shop Name")}
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder={t("Shop Name")}
                            name="shop_name"
                            onChange={onChange}
                            value={account.shop_name}
                            type="text"
                        />
                    </FormGroup>
                </Col>
                <ProfileImage account={account} handleFileUpload={handleFileUpload} />
                <Col lg="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="category">
                            {t("Category")}
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="category"
                            placeholder={t("Category")}
                            name="category"
                            onChange={onChange}
                            value={account.category}
                            type="select">
                            {categories.map(c =>
                                <option key={c.key} value={c.key}>{c.value}</option>
                            )}
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg="12">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="about_html">
                            {t("About the shop")}</label>
                        <Input
                            id="about_html"
                            className="form-control-alternative"
                            placeholder={t("Few words about the business")}
                            onChange={onChange}
                            value={account.about_html}
                            name="about_html"
                            rows="4"
                            maxLength="1500"
                            type="textarea"
                        />
                        <small style={(1500 - account.about_html.length) < 20 ? { color: "red" } : null}>{t("Characters remaining") + ": " + (1500 - account.about_html.length)} </small>
                    </FormGroup>
                </Col>
            </Row>
        </div></>);
}

export default withTranslation()(ShopProfile);