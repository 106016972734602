import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

function MandatoryForValidation({ text, id, t }) {
    const [isOpen, setIsOpen] = useState(false);
    return (<>
        <i style={{ marginLeft: 4, color: "red" }} id={id} className="fas fa-star-of-life" onClick={() => setIsOpen(!isOpen)} />
        <UncontrolledPopover placement="bottom" target={id} className="popover-primary">
            <PopoverBody style={{ color: "black" }}>
                {t("This field is mandatory for account validation")}
            </PopoverBody>
        </UncontrolledPopover>
    </>);
}

export default withTranslation()(MandatoryForValidation);