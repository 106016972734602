import React from 'react';
import { Row, Col, FormGroup, Input, InputGroupText, InputGroupAddon, InputGroup, Button, ButtonGroup, Alert } from "reactstrap";
import DonatorProfile from './Donator-profile';
import { withTranslation } from 'react-i18next';
import ProfileImage from './ProfileImage';
import InfoWithTooltip from 'components/Small/InfoWithTooltip';
import DocumentUploader from './DocumentUploader';
import ReactDatetime from 'react-datetime';
import { useState } from 'react';
import CharityVisibilityModal from './Charity-visibility-modal';
import MandatoryForValidation from 'components/Small/MandatoryForValidation';

function CharityProfile({ account, onChange, handleFileUpload, categories, t, visibility, unsavedChangesExist }) {
    const [showVisibilitySettings, setShowVisibilitySettings] = useState(false);
    const setDziSource = source => {
        // source = upload OR external
        let changeObj = {
            target: {
                name: "dzi_external",
                value: source === 'external',
            }
        }
        onChange(changeObj);
    }

    const getProofLabel = () => (
        <>
            <label
                className="form-control-label"
                htmlFor="year_founded">
                {t("Proof of charity")}
            </label>
            <MandatoryForValidation id="proof_validation" />
            <InfoWithTooltip id="proof_info" text={t("Charity Proof Certificate explanation")} />
            {account.proof_url.length > 0 ?
                <a href={account.proof_url} target="_blank" rel="noreferrer"> {t("Current proof")}</a>
                : null}
        </>
    )

    const getFoundingYears = () => {
        const maxYear = new Date().getFullYear();
        const minYear = 1880;
        let years = [<option key="" value={""}></option>];
        for (let i = maxYear; i > minYear; i--)
            years.push(<option key={i} value={i}>{i}</option>)
        return years;
    }

    const launchRuleConfigModal = () => {
        setShowVisibilitySettings(true);
    }

    return (<>
        {showVisibilitySettings ?
            <CharityVisibilityModal isOpen={showVisibilitySettings}
                visibilityApproach={account.visibility_approach} charityId={account.id} toggleModal={() => { setShowVisibilitySettings(!showVisibilitySettings) }} />
            : ""}
        <DonatorProfile entity={account} onChange={onChange} />
        <hr className="my-4" />
        {/* Description */}
        <h6 className="heading-small text-muted mb-4">{t("About the charity")}</h6>
        <Row>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="name">
                        {t("Charity name")}
                    </label><MandatoryForValidation id="name_validation" />
                    <Input
                        className="form-control-alternative"
                        id="name"
                        placeholder={t("Charity name")}
                        name="name"
                        onChange={onChange}
                        value={account.name}
                        type="text"
                    />
                </FormGroup>
            </Col>
            <ProfileImage account={account} handleFileUpload={handleFileUpload} />
        </Row>
        <Row>
            <DocumentUploader account={account} t={t} type="proof" handleFileUpload={handleFileUpload} getLabel={getProofLabel} />
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="year_founded">
                        {t("Founding year")}
                    </label>

                    <Input
                        className="form-control-alternative"
                        id="year_founded"
                        placeholder={t("Founding year")}
                        name="year_founded"
                        onChange={evt => onChange({ target: { name: "year_founded", value: parseInt(evt.target.value) } })}
                        value={account.year_founded}
                        type="select">
                        {getFoundingYears()}
                    </Input>
                    {/* <Input
                            className="form-control-alternative"
                            id="year_founded"
                            placeholder={t("Founding year")}
                            name="year_founded"
                            onChange={evt => {
                                let value = parseInt(evt.target.value);
                                if (value < 1900)
                                    value = 1900;
                                onChange({ target: { name: "year_founded", value } })
                            }}
                            value={account.year_founded}
                            type="number"
                            min="0"
                            max={new Date().getFullYear()}>
                        </Input> */}
                </FormGroup>
            </Col>
            {/* <Col lg="6"> */}
            {/* </Col> */}
        </Row>
        <Row>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="category">
                        {t("Charity category")}
                    </label><MandatoryForValidation id="category_validation" />
                    <Input
                        className="form-control-alternative"
                        id="category"
                        placeholder={t("Charity category")}
                        name="category"
                        onChange={onChange}
                        value={account.category}
                        type="select">
                        {categories.map(c =>
                            <option key={c.key} value={c.key}>{c.value}</option>
                        )}
                    </Input>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="mission_html">
                        {t("Our mission")}</label>
                    <Input
                        id="mission_html"
                        className="form-control-alternative"
                        placeholder={t("Few words about the charity organisation")}
                        onChange={onChange}
                        value={account.mission_html}
                        name="mission_html"
                        rows="4"
                        maxLength="1500"
                        type="textarea"
                    />
                    <small style={(1500 - account.mission_html.length) < 20 ? { color: "red" } : null}>{t("Characters remaining") + ": " + (1500 - account.mission_html.length)} </small>
                </FormGroup>
            </Col>
            <Col lg="12">
                <div className="custom-control custom-checkbox mb-3">
                    <input
                        className="custom-control-input"
                        id="dzi_certificate"
                        type="checkbox"
                        checked={account.dzi_certificate}
                        name="dzi_certificate"
                        onChange={onChange}
                    />
                    <label className="custom-control-label" htmlFor="dzi_certificate">{t("DZI Certificate")}</label><InfoWithTooltip id="dzi_cert_info" text={t("DZI Certificate explanation")} />
                </div>
            </Col>
        </Row>
        {account.dzi_certificate ?
            <>
                <Row className="justify-content-md-center">
                    <Col lg="6">
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="dzi_validity">
                                {t("DZI Validity Date")}
                            </label>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-calendar-grid-58" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <ReactDatetime
                                    inputProps={{
                                        placeholder: t("DZI Validity Date"),
                                        name: "dzi_validity",
                                        value: account["dzi_validity"]
                                    }}
                                    onChange={e => onChange({ target: { type: "date", value: e.format("YYYY-MM-DD"), name: "dzi_validity" } })}
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                {account.dzi_url.length > 1 ?
                    <Row className="justify-content-md-center">
                        <p><a href={account.dzi_url} target="_blank" rel="noreferrer">{t("Current certificate")}</a></p>
                    </Row>
                    : null}
                {account.dzi_validity < new Date().toISOString() ?
                    <Row className="justify-content-md-center">
                        <Alert color="warning">
                            <span className="alert-inner--icon">
                                <i className="ni ni-calendar-grid-58" />
                            </span>{" "}
                            <span className="alert-inner--text">
                                <strong>{t("Expired!")}</strong> {t("The certification validity date has passed. Please update it.")}</span>
                        </Alert>
                    </Row>
                    : null}
                <Row className="justify-content-md-center" style={{ textAlign: "center" }}>
                    <Col lg="6">
                        <FormGroup>
                            <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                                <Button className={account.dzi_external ? 'active' : ''} color="secondary" onClick={() => setDziSource("external")}>
                                    <input
                                        autoComplete="off"
                                        name="options"
                                        type="radio"
                                        value={account.dzi_external}
                                    />{t("External URL")}</Button>
                                <Button className={!account.dzi_external ? 'active' : ''} color="secondary" onClick={() => setDziSource("upload")}>
                                    <input
                                        autoComplete="off"
                                        name="options"
                                        type="radio"
                                        value={!account.dzi_external}
                                    />{t("Upload")}</Button>
                            </ButtonGroup>
                        </FormGroup>
                    </Col>
                </Row>
                {
                    account.dzi_external ?
                        <Row className="justify-content-md-center">
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="dzi_url">
                                        {t("DZI URL")}
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="dzi_url"
                                        placeholder={t("DZI URL")}
                                        name="dzi_url"
                                        onChange={onChange}
                                        value={account.dzi_url}
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        :
                        <>
                            <Row className="justify-content-md-center">
                                <DocumentUploader account={account} t={t} type="dzi" handleFileUpload={handleFileUpload} />
                            </Row></>}
            </> : ""}
        <hr className="my-4" />
        {/* Description */}
        <h6 className="heading-small text-muted mb-4">{t("Selection of donators")}</h6>
        <Row>
            <Col lg="12" xl="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="visibility_approach">
                        {t("Default acceptance approach")}
                    </label><InfoWithTooltip id="visibility_approach_info" text={t("Default acceptance approach info text")} />
                    <InputGroup>
                        <Input
                            className="form-control-alternative"
                            id="visibility_approach"
                            name="visibility_approach"
                            onChange={evt => { onChange({ target: { name: "visibility_approach", value: evt.target.value } }) }}
                            value={account.visibility_approach}
                            type="select">
                            <option key="BLACKLIST" value="BLACKLIST">{t("Accept donations")}</option>
                            <option key="WHITELIST" value="WHITELIST">{t("Reject donations")}</option>
                        </Input>
                        <InputGroupAddon addonType="append">
                            <Button disabled={unsavedChangesExist} title={unsavedChangesExist ? t("You need to save the settings to access the configuration") : ""} color="default" onClick={launchRuleConfigModal}>
                                <span>{t("Rules")}</span>
                                {/* <Badge id="vis_config_button" color="primary">{visibility.length || 0}</Badge> */}
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
            </Col>
        </Row></>);
}

export default withTranslation()(CharityProfile)