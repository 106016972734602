import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, UncontrolledPopover } from "reactstrap";
import styled from 'styled-components';

const ExportAsCSV = ({exportDonationsDataAsCSV, t }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState({ 
    dateTo: new Date(),
    dateFrom: new Date(),
  });
  const [statusToSendToDb, setStatusToSendToDb] = useState([]);
  const [status, setStatus] = useState({
    confirmed: true,
    pending: false,
    cancelled: false,
  });

  const handleOnChange = (e) => { 
    setStatus({...status, [e.target.name.toLowerCase()]: e.target.checked});  
  }

  useEffect(() => {
    let temp = [];
    Object.keys(status).forEach(key => {
      const upperCaseKey = key.toUpperCase();
      if(status[key] === true){
        if(temp.indexOf(upperCaseKey) === -1) {
          temp = [...temp, upperCaseKey]
        }
      } else {
        if(temp.indexOf(upperCaseKey) !== -1){
          temp.splice(temp.indexOf(upperCaseKey), 1)
        }
      } 
    })
    setStatusToSendToDb([...temp])
  }, [status])

  const handleExportCSV = () => { 
    const dateObj = {
      from_date:  moment(date.dateFrom).format("Y-M-D"),
      to_date: moment(date.dateTo).format("Y-M-D"),
      status: statusToSendToDb
    }
    exportDonationsDataAsCSV(dateObj);
  }

  const statusArr = [
    {
      status: 'Confirmed',
      checked: status.confirmed
    },
    {
      status: 'Pending',
      checked: status.pending
    },
    {
      status: 'Cancelled',
      checked: status.cancelled
    },
  ]

  return (
    <ExportCSVWrapper>
      <StyledButton 
        onClick={() => setOpen(!open)}
        id="btn"
      >
        <i className="fas fa-file-download"></i>
        <span>{t("CSV Herunterladen")}</span>
      </StyledButton>
      <UncontrolledPopover placement="bottom" target="btn" isOpen={open}>
        <Wrapper>
          <CloseBtn 
            onClick={() => setOpen(false)}
          >
            <i className="fa fa-times" />
          </CloseBtn>
          <div>
            <h5 style={{marginBottom: "4px"}}>{t("Ab")}</h5>
            <Input 
              type="date"
              name="dateFrom"
              value={moment(date.dateFrom).format("YYYY-MM-DD")}
              onChange={(e) => setDate({...date, dateFrom: e.target.value})}
            />
            <h5 style={{marginBottom: "4px"}}>{t("Bis")}</h5>
            <Input 
              type="date"
              name="dateTo"
              value={moment(date.dateTo).format("YYYY-MM-DD")}
              onChange={(e) => setDate({...date, dateTo: e.target.value})}
            />
          </div>
          <div>
            <h5>{t("Spendenstatus auswählen")}</h5>
            {statusArr.map((item, index) => {
              const {status, checked} = item;
              return (
                <div key={index} className="custom-control custom-checkbox mb-3">
                  <input
                    className="custom-control-input"
                    id={status}
                    type="checkbox"
                    checked={checked}
                    name={status}
                    onChange={handleOnChange}
                  />
                  <label className="custom-control-label" htmlFor={status}>{t(status)}</label>
                </div>
              )
            })}
          </div>
          <div>
            <BtnStyled
              size="sm"
              outline 
              color="danger"
              onClick={() => setOpen(false)}
            >
              {t("Abbrechen")}
            </BtnStyled>
            <BtnStyled 
              outline 
              size="sm" 
              color="info"
              onClick={handleExportCSV}
            >
              {t("Herunterladen")}
            </BtnStyled>
          </div>
        </Wrapper>
      </UncontrolledPopover>     
    </ExportCSVWrapper>
  )
}

export default ExportAsCSV;

const ExportCSVWrapper = styled.div`
  @media only screen and (max-width: 728px) {
    width: 100%;
  }
`
const StyledButton = styled(Button)`
  box-shadow: none !important;
  @media only screen and (max-width: 728px) {
    width: 100%;
  }
`
const Wrapper = styled.div`
  padding: 20px;
  position: relative;
`

const Input = styled.input`
  width: 194px;
  border: 0px;
  color: #7d888d;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
  font-size: 14px;
  margin-bottom: 10px;
  &:focus {
    border: 1px solid rgb(61, 145, 255);
  }
`
const CloseBtn = styled.span`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
`
const BtnStyled = styled(Button)`
  box-shadow: none !important;
`