import { SET_DONATIONS } from "redux/constants/donation-entity-constants";


export const donationEntityInitialState = {
    donations: [],
    donationQueryObj: {}
}


export default (state = donationEntityInitialState, action) => {
    switch (action.type) {
        case SET_DONATIONS:
            return Object.assign({}, state, {
                donations: action.payload.donations,
                donationQueryObj: action.payload.queryObj
            });
        default:
            return state;
    }
}