import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvder from 'react-bootstrap-table2-toolkit';
import { Col, Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
// import i18n from 'i18n';

const headerItemStyling = { paddingLeft: 0, paddingRight: 0, marginBottom: 8 };

export default function DataTable({ pagingConfig, columns, data, fetchData, isSearchEnabled, addNew, AddNewDialog, setShowAddNewDialog, showAddNewDialog, addNewProps, addNewText }) {
    //structure of tableId: string
    //structure of headerLine: array with properties id, label, name
    //structure of data: array with property names = name from headerLine
    //value of data can either be string/int or Component/Element allowed by Table element
    // const [queryUrl, setQueryUrl] = useState("");
    // const [showAddNewDialog, setShowAddNewDialog] = useState(false);
    const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        // let queryUrl = "?";
        // queryUrl += page ? ("offset=" + ((page - 1) * sizePerPage)) : "";
        // queryUrl += sizePerPage ? ("&limit=" + sizePerPage) : "";
        // queryUrl += sortField ? ("&sort_by=" + sortField + "&order_by=" + sortOrder) : "";
        // setQueryUrl(queryUrl);
        fetchData({ page, sizePerPage, sortField, sortOrder });
    }

    const AddNewButton = props => (
        <Col xs="12" xl="12" style={headerItemStyling} className="text-right">
            <Button className="btn-icon btn-2" color="success" type="button" size="sm" onClick={() => { setShowAddNewDialog(true) }}>
                <span className="btn-inner--icon">
                    <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">{addNewText}</span>
            </Button>
            {/* <Button size="sm"
                color="success"
                onClick={() => { console.log("New to be added") }}>
                Add New
        </Button> */}
        </Col>
    )

    // const AddNewForm = props => {
    //     const [formData, setFormData] = useState({});
    //     const onSubmit = (evt) => {
    //         evt.preventDefault();
    //         evt.stopPropagation();
    //         let serializedResult = {};
    //         Array.from(evt.currentTarget.elements).map(el => {
    //             if (el.name)
    //                 serializedResult[el.name] = el.value;
    //             return el;
    //         });
    //         addNew(serializedResult);
    //         setShowAddNewDialog(false);
    //     };
    //     const onChange = evt => {
    //         setFormData({ ...formData, [evt.target.name]: evt.target.value });
    //     }
    //     return (
    //         <Container style={{ border: "1px solid var(--primary)", borderRadius: 4, padding: 8, marginTop: 8, marginBottom: 8 }}>
    //             <div className="text-center">
    //                 <h3>Add New Item</h3>
    //             </div>
    //             <Form onSubmit={onSubmit}>
    //                 <Row>
    //                     {props.columns.map(col => {
    //                         if (!col.addNew)
    //                             return null;
    //                         return <Col lg="4" key={col.dataField + "_col"}>
    //                             <FormGroup>
    //                                 <label
    //                                     className="form-control-label small"
    //                                     htmlFor={"input-" + col.dataField}>{col.text}</label>
    //                                 {col.addNew.element ? col.addNew.element(formData, onChange) :
    //                                     <Input bsSize="sm" type={col.addNew.type}
    //                                         placeholder={col.text}
    //                                         name={col.dataField}
    //                                         value={formData[col.dataField]}
    //                                         onChange={onChange}
    //                                         id={"input-" + col.dataField} />
    //                                 }
    //                             </FormGroup>
    //                         </Col>
    //                     })}
    //                 </Row>
    //                 <div className="text-center">
    //                     <Button color="success" size="sm" type="submit">Save</Button>
    //                     <Button size="sm" color="primary" onClick={() => { setShowAddNewDialog(false) }}>Cancel</Button>
    //                 </div>
    //             </Form>
    //         </Container>
    //     )
    // }

    const CustomSearch = props => {
        // let input;
        // const handleClick = () => {
        // throw 'a';
        // props.onSearch(input.value);
        // }
        return (<>
            {/* <Col xs="6" xl="6" style={headerItemStyling}> */}
            {/* <InputGroup>
                    <Input
                        className="form-control"
                        type="text"
                        placeholder="Search"
                        size="sm"
                        innerRef={i => input = i} />
                    <InputGroupAddon addonType="append">
                        <Button
                            size="sm"
                            color="primary"
                            onClick={handleClick}
                        >Search</Button>
                    </InputGroupAddon>
                </InputGroup> */}
            {/* </Col> */}
        </>
        )
    }
    return (
        <ToolkitProvder
            keyField="id"
            data={data}
            columns={columns}
            {...isSearchEnabled && 'search'}
            bootstrap4
            // filter={filterFactory()}
            noDataIndication={() => <h2>Nothing found</h2>}
        >
            {props => {
                return (<>
                    <Row style={{ marginLeft: 0, marginRight: 0 }}>
                        {isSearchEnabled && <CustomSearch {...props.searchProps} onChange={() => { alert("changed") }} />}
                        {showAddNewDialog ? <AddNewDialog {...props} addNew={addNew} setShowAddNewDialog={setShowAddNewDialog} /> :
                            (addNew && <AddNewButton />)
                        }
                    </Row>
                    <BootstrapTable wrapperClasses="table-responsive" {...props.baseProps}
                        pagination={paginationFactory({ page: pagingConfig.page, totalSize: pagingConfig.totalSize, sizePerPage: pagingConfig.sizePerPage })}
                        remote
                        condensed
                        striped
                        onTableChange={onTableChange}
                    />
                </>
                )
            }
            }
        </ToolkitProvder >)

}