
import { SET_SHOPS } from "redux/constants/shop-entity-constants";

export const shopEntityInitialState = {
    shops: []
}


export default (state = shopEntityInitialState, action) => {
    switch (action.type) {
        case SET_SHOPS:
            return Object.assign({}, state, {
                shops: action.payload
            });
        default:
            return state;
    }
}