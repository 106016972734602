import { EXPORT_AS_CSV } from "redux/constants/donation-entity-constants"
import { SET_DONATIONS } from "redux/constants/donation-entity-constants"
import { FETCH_DONATIONS } from "redux/constants/donation-entity-constants"

export function fetchDonations(payload) {
    return { type: FETCH_DONATIONS, payload }
}

export function setDonations(payload) {
    return { type: SET_DONATIONS, payload }
}

export function exportAsCSV(payload) {
    return { type: EXPORT_AS_CSV, payload }
}
