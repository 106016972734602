/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import DashboardNavbar from "components/Navbars/DashboardNavbar.js";
import DashboardFooter from "components/Footers/DashboardFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import logo from 'assets/img/brand/logo.png';

import routes from "routes.js";
import { connect } from "react-redux";
import { fetchAccount } from "redux/actions/dashboard-actions";
import { fetchSettings } from "redux/actions/dashboard-actions";
import { fetchCategories } from "redux/actions/dashboard-actions";
// import Tour from "reactour";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import SuccessErrorMessage from "components/General/settings-components/SuccessErrorMessage";
import { fetchPlatforms } from "redux/actions/dashboard-actions";
// import SuccessErrorMessage from "components/General/settings-components/SuccessErrorMessage";


class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      walkthroughCompleted: true
    }
    this.completeWalkthrough = this.completeWalkthrough.bind(this);
  }

  tourSteps = [
    {
      selector: '#navbar-main', content:
        <>
          <div>
            <h2>{this.props.t("Welcome to your private dashboard")}</h2>
            <p>{this.props.t("Here, you can view and change your account data.")}</p>
            <small>{this.props.t("Let us quickly show you around. Use the arrows to learn how to navigate around.")}</small>
          </div>
        </>
    },
    // { selector: '#menu_option_dashboard', content: this.props.t("The dashboard shows you the overview of your current account status. Keep an eye on it, it get's improved very frequently!") },
    { selector: '#menu_option_profile', content: this.props.t("Your profile is how other Rest-Cent members see you, if you decide to make it public.") },
    { selector: '#menu_option_settings', content: this.props.t("In the settings section, you are able to control how you use Rest-Cent.") },
  ]

  componentDidMount() {
    this.props.fetchAccount();
    this.props.fetchSettings();
    this.props.fetchCategories();
    if (localStorage.getItem("accType") === "SHOP") {
      this.props.fetchPlatforms();
    }
    this.setState({ walkthroughCompleted: document.cookie.includes("walkthroughCompleted=true") })
    // this.props.fetch
  }

  completeWalkthrough() {
    document.cookie = "walkthroughCompleted=true";
    this.setState({ walkthroughCompleted: true });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dashboard") {
        const Component = prop.component;
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <Component {...props} />}
            // component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/dashboard",
            imgSrc: logo,
            imgAlt: "..."
          }}
        />
        <div className="main-content" style={{ position: "relative" }} ref="mainContent">
          {/* <div style={{ position: "fixed" }} className="row justify-content-md-center"> */}
          {/* </div> */}
          <DashboardNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/dashboard/user-profile" />
          </Switch>
          {/* <SuccessErrorMessage /> */}
          <Container fluid>
            <DashboardFooter />
          </Container>
        </div>
        {/* <Tour steps={this.tourSteps} isOpen={!this.state.walkthroughCompleted} onRequestClose={this.completeWalkthrough} /> */}
        {/* <Alert style={alertStyling} color="success"><strong>GOOD JOB!</strong></Alert>; */}
        <SuccessErrorMessage />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    // account: state.dashboard.account,
    // settings: state.dashboard.settings
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchAccount: () => dispatch(fetchAccount()),
    fetchSettings: () => dispatch(fetchSettings()),
    fetchCategories: () => dispatch(fetchCategories()),
    fetchPlatforms: () => dispatch(fetchPlatforms())
  }
}
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Dashboard);
