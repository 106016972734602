import i18n from "i18n";
import { call, put, takeLatest } from "redux-saga/effects";
import { setDonations } from "redux/actions/donation-entity-actions";
import { finishLoading } from "redux/actions/global-actions";
import { setErrorMessage } from "redux/actions/global-actions";
import { setLoading } from "redux/actions/global-actions";
import { apiExportDonationAsCSV } from "redux/api/donation-entity-api";
import { apiFetchDonations } from "redux/api/donation-entity-api";
import { EXPORT_AS_CSV } from "redux/constants/donation-entity-constants";
import { FETCH_DONATIONS } from "redux/constants/donation-entity-constants";

function* fetchDonations({ payload }) {
    try {
        yield put(setLoading());
        const donations = yield call(apiFetchDonations, payload);
        if (!payload.queryObj) {
            payload.queryObj = {};
            payload.queryObj.page = 1;
            payload.queryObj.sizePerPage = 25;
            payload.queryObj.totalSize = parseInt(donations.count);
        }
        // yield put(setCharityVisRules({ rules: rules.data, queryObj: payload.queryObj }));
        console.log("query: " + JSON.stringify(payload.queryObj));
        yield put(setDonations({ donations: donations.data, queryObj: payload.queryObj }));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred")));
    }
}

function* exportDonationsDataAsCSV({ payload }) {
    try {
        yield put(setLoading());
        const data = yield call(apiExportDonationAsCSV, payload);

        // download as csv
        const url = window.URL.createObjectURL(new Blob([data])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'donation.csv')
        document.body.appendChild(link)
        link.click()

        yield put(finishLoading());
    } catch (error) {
       yield put(setErrorMessage(i18n.t("Error occurred while downloading CSV"))); 
    }
}

export default function* shopEntitySaga() {
    yield takeLatest(FETCH_DONATIONS, fetchDonations)
    yield takeLatest(EXPORT_AS_CSV, exportDonationsDataAsCSV)
}