// combineReducers come from redux that used for combining reducers that we just made.
import { combineReducers } from 'redux'

// Reducers
import authentication from './authentication-reducers';
import global from './global-reducers';
import dashboard from './dashboard-reducers';
import shops from './shop-entity-reducers';
import donations from './donation-entity-reducers';

export default combineReducers({
    authentication,
    global,
    dashboard,
    shops,
    donations
    // Here you can registering another reducers.
})