import { AUTH_FAILED } from "redux/constants/authentication-constants";
import { AUTH_SUCCESS } from "redux/constants/authentication-constants";
import { SET_AWAITS_CODE } from "redux/constants/authentication-constants";
import { CLEAR_AWAITS_CODE } from "redux/constants/authentication-constants";
import { RESEND_CODE } from "redux/constants/authentication-constants";
import { SET_SIGNEDIN } from "redux/constants/authentication-constants";
import { LOGOUT } from "redux/constants/authentication-constants";
import { SET_RESET_PENDING } from "redux/constants/authentication-constants";
import i18n from 'i18n';
import { SET_EMAIL } from "redux/constants/authentication-constants";
import { SET_USER_ID_RESPONSE } from "redux/constants/authentication-constants";

const { SIGN_IN } = require("redux/actions/authentication-actions");
const { SIGN_UP } = require("redux/actions/authentication-actions");

export const initialState = {
    loading: false,
    tokens: {
        access_token: null,
        id_token: null,
        refresh_token: null
    },
    sessionExpiry: new Date() - 1,
    errorMessage: "",
    successMessage: "",
    redirectTo: "",
    awaitsCode: false,
    accType: "USER",
    forgotPasswordPending: false,
    email: "",
    user: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SIGN_UP:
            return Object.assign({}, state, {
                data: action.payload
            });
        case SIGN_IN:
            return Object.assign({}, state, {
                data: action.payload
            });
        case AUTH_FAILED:
            return Object.assign({}, state, {
                errorMessage: action.payload,
                successMessage: ""
            });
        case AUTH_SUCCESS:
            return Object.assign({}, state, {
                errorMessage: "",
                successMessage: action.payload
            });
        case SET_AWAITS_CODE:
            return Object.assign({}, state, {
                awaitsCode: true
            });
        case CLEAR_AWAITS_CODE:
            return Object.assign({}, state, {
                awaitsCode: false
            });
        case RESEND_CODE:
            return Object.assign({}, state, {
                email: action.payload
            });
        case SET_SIGNEDIN:
            localStorage.setItem("sessionExpiry", action.payload.date.toString());
            localStorage.setItem("accType", action.payload.accType);
            return Object.assign({}, state, {
                sessionExpiry: action.payload.date,
                accType: action.payload.accType
            });
        case LOGOUT:
            localStorage.removeItem("sessionExpiry");
            localStorage.removeItem("accType");
            return Object.assign({}, state, {
                sessionExpiry: new Date(),
                successMessage: i18n.t("You have successfully logged out")
            });
        case SET_RESET_PENDING:
            return Object.assign({}, state, {
                forgotPasswordPending: true,
            });
        case SET_EMAIL:
            return Object.assign({}, state, {
                email: action.payload
            });
        case SET_USER_ID_RESPONSE:
            return Object.assign({}, state, {
                user: action.payload
            });
        default:

            break;
    }
    return state;
}