import { SET_LOADING } from "redux/constants/global-constants";
import { FINISH_LOADING } from "redux/constants/global-constants";
import { SET_REDIRECT, CLEAR_REDIRECT } from "redux/constants/global-constants";
import { SET_SUCCESS_MESSAGE } from "redux/constants/global-constants";
import { SET_ERROR_MESSAGE } from "redux/constants/global-constants";
import { SET_UPLOADED_FILE_URL } from "redux/constants/global-constants";

export function setLoading() {
    return { type: SET_LOADING }
}

export function finishLoading() {
    return { type: FINISH_LOADING }
}

export function setRedirect(payload) {
    return { type: SET_REDIRECT, payload }
}

export function clearRedirect(payload) {
    return { type: CLEAR_REDIRECT, payload };
}

export function setSuccessMessage(payload) {
    return { type: SET_SUCCESS_MESSAGE, payload }
}

export function setErrorMessage(payload) {
    return { type: SET_ERROR_MESSAGE, payload }
}
export function setUploadedFileUrl(payload) {
    return { type: SET_UPLOADED_FILE_URL, payload }
}