/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { cloneElement, useEffect, useState } from "react";
import styled from 'styled-components';
import moment from 'moment';

// core components
import Header from "components/Headers/Header.js";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { fetchDonations } from "redux/actions/donation-entity-actions";
import { setDonations } from "redux/actions/donation-entity-actions";
import DataTable from "components/DataDisplay/DataTable";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import { exportAsCSV } from "redux/actions/donation-entity-actions";
import ExportAsCSV from "components/ExportAsCSV/ExportAsCSV";


const columns = ({ t }) => ([
  {
    text: "ID",
    dataField: "id",
    hidden: true
  }, {
    text: t("Date"),
    dataField: "created_at",
    sort: true,
    formatter: cell => {
      return new Date(cell).toLocaleString('de-DE')
    }
  }, {
    text: t("Amount"),
    dataField: "amount",
    sort: true,
    align: "center",
    formatter: cell => Intl.NumberFormat("de-DE", { style: 'currency', currency: 'EUR' }).format(cell)
  },
  {
    text: t("Status"),
    dataField: "status",
    sort: true,
    align: "center",
    formatter: cell => {
      switch (cell) {
        case "CONFIRMED":
          return <i style={{ color: "green" }} className="fas fa-check" title={t("Confirmed")} />;
        case "PENDING":
          return <i style={{ color: "orange" }} className="fas fa-stopwatch" title={t("Pending")} />;
        case "CANCELLED":
          return <i style={{ color: "red" }} className="fas fa-times" title={t("Cancelled")} />;
        case "ABANDONED":
          return <i style={{ color: "grey" }} className="fas fa-archive" title={t("Abandoned")} />;
        default:
          return "";
      }
    }
  },
  {
    text: t("Charity"),
    sort: true,
    dataField: "charity.id",
    formatter: (cell, row) => {
      return row.charity.name;
    }
  },
  {
    text: t("Type"),
    sort: true,
    align: "center",
    dataField: "owner_donation",
    formatter: (cell, row) => {
      return row.owner_donation ? <i title={t("Shop donation")} className="fas fa-store" /> : <i title={t("User donation")} className="fas fa-user" />;
    }
  },
  {
    text: t("Order number"),
    align: "center",
    dataField: "ext_reference",
    formatter: cell => cell.length < 15 ? cell : ""
  }
]);

function Donations({ 
  history, 
  t,
  donations,
  fetchDonations,
  donationQueryObj = { page: 1, totalSize: 25, sizePerPage: 25 },
  exportDonationsDataAsCSV
 }) {
  useEffect(() => {
    if (localStorage.getItem("accType") === "CHARITY")
      return history.push("/dashboard");
    fetchDonations();
  }, [fetchDonations, history])

  return (<>
    <Header />
    <Container className="mt--7" fluid>
      {/* Table */}
      <Row>
        <div className="col">
          <Card className="shadow">
            <StyledCardHeader className="border-0">
              <h3>{t("Donations")}</h3>
              <ExportAsCSV 
                t={t} 
                exportDonationsDataAsCSV={exportDonationsDataAsCSV}
              />
            </StyledCardHeader>
            <DataTable tableId="my_donations_table"
              isSearchEnabled
              columns={columns({ t })}
              pagingConfig={donationQueryObj}// TODO
              fetchData={fetchDonations}
              data={donations} />
          </Card>
        </div>
      </Row>
    </Container>
  </>);
}

const mapStateToProps = state => {
  return {
    donations: state.donations.donations,
    donationQueryObj: state.donations.donationQueryObj
  }
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDonations: queryObj => dispatch(fetchDonations({ queryObj })),
    setDonations: donations => dispatch(setDonations(donations)),
    exportDonationsDataAsCSV: payload => dispatch(exportAsCSV(payload)),
  }
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Donations);

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 728px) {
    flex-direction: column;
    align-items: flex-start;
    h1 {
      margin-bottom: 10px;
    }
  }
`