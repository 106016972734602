import {
    put,
    call,
    // takeLatest,
    takeEvery,
    delay,
    takeLatest
} from 'redux-saga/effects'
import { SIGN_IN } from 'redux/constants/authentication-constants'
import { SIGN_UP } from 'redux/constants/authentication-constants';
import { apiSignIn, apiSignUp, apiVerifyAccount } from 'redux/api/authentication-api';
import { authSuccess } from 'redux/actions/authentication-actions';
import { authFailed } from 'redux/actions/authentication-actions';
import { VERIFY_ACCOUNT } from 'redux/constants/authentication-constants';
import { finishLoading } from 'redux/actions/global-actions';
import { setLoading } from 'redux/actions/global-actions';
import { setAwaitsCode } from 'redux/actions/authentication-actions';
import { clearAwaitsCode } from 'redux/actions/authentication-actions';
// import { apiSelectAccountType } from 'redux/api/authentication-api';
import { RESEND_CODE } from 'redux/constants/authentication-constants';
import { apiResendCode } from 'redux/api/authentication-api';
import { setRedirect } from 'redux/actions/global-actions';
import { setSignedIn } from 'redux/actions/authentication-actions';
import { apiSelectAccountType } from 'redux/api/authentication-api';
import { SET_TYPE } from 'redux/constants/authentication-constants';
import { setForgotPasswordPending } from 'redux/actions/authentication-actions';
import { apiResetPassword } from 'redux/api/authentication-api';
import { apiConfirmPasswordReset } from 'redux/api/authentication-api';
import { RESET_PASSWORD } from 'redux/constants/authentication-constants';
import { CONFIRM_FORGOT_PASSWORD } from 'redux/constants/authentication-constants';
import i18n from 'i18n'
import { setEmail } from 'redux/actions/authentication-actions';
import { GET_USER_ID } from 'redux/constants/authentication-constants';
import { apiGetCurrentUserId } from 'redux/api/authentication-api';
import { setUserIdResponse } from 'redux/actions/authentication-actions';
import { LOGOUT } from 'redux/constants/authentication-constants';
import { apiLogUserOut } from 'redux/api/authentication-api';

function* signUserIn({ payload }) {
    try {
        yield put(setLoading());
        const copy = Object.assign({}, payload)
        const same_page_refresh = copy.same_page_refresh;
        delete payload.same_page_refresh;
        const result = yield call(apiSignIn, payload);
        // console.log("result: " + JSON.stringify(result));
        yield put(authSuccess(i18n.t('Logged in, redirecting...')));
        let date = new Date();
        date.setDate(date.getDate() + 30);
        yield put(setSignedIn({ date: date, accType: result.data.accType }));
        yield delay(1000);
        yield put(authSuccess());
        // the component can specify the URL, to which the app should navigate to, after auth
        if (same_page_refresh)
            window.location.reload(false);
        else
            yield put(setRedirect(!result.data.accType ? "/auth/typeselection" : "/dashboard/user-profile"));
        yield put(finishLoading());
    } catch (e) {
        // console.error("saga auth error: " + JSON.stringify(e));
        if (e.response && e.response.data && e.response.data.message === "User is not confirmed.") {
            yield put(setEmail(payload.email));
            yield put(setRedirect("/auth/register"));
            yield put(setAwaitsCode());
        }
        else
            yield put(authFailed((e.response && e.response.data) ? e.response.data.message : i18n.t("Error occurred")));
    }
}

function* signUserUp({ payload }) {
    try {
        yield put(setLoading());
        yield call(apiSignUp, payload);
        yield put(authSuccess());
        yield put(setAwaitsCode());
        yield put(finishLoading());
    } catch (e) {
        // console.error("saga registration error: " + JSON.stringify(e.response));
        yield put(authFailed((e.response && e.response.data) ? e.response.data.message : i18n.t("Error occurred")));
    }
}

function* verifyAccount({ payload }) {
    try {
        yield put(setLoading());
        yield call(apiVerifyAccount, payload);
        yield put(authSuccess(i18n.t("Your account has been verified. Please log in.")));
        // yield put(apiSelectAccountType, payload);
        yield put(setRedirect("/auth/login"));
        yield put(clearAwaitsCode());
        yield put(finishLoading());
    } catch (e) {
        yield put(authFailed((e.response && e.response.data) ? e.response.data.message : i18n.t("Error occurred")));
    }
}

function* resendCode({ payload }) {
    try {
        yield put(setLoading());
        yield call(apiResendCode, payload);
        yield put(authSuccess(i18n.t("We have resent the code.")));
        yield put(finishLoading());
    } catch (e) {
        yield put(authFailed((e.response && e.response.data) ? e.response.data.message : i18n.t("Error occurred")));
    }
}

function* setAccountType({ payload }) {
    try {
        yield put(setLoading());
        yield call(apiSelectAccountType, payload);
        localStorage.setItem("accType", payload.account_type);
        yield put(setRedirect("/dashboard/user-profile"));
        yield put(finishLoading());
    } catch (e) {
        console.error(e);
        yield put(authFailed((e.response && e.response.data) ? e.response.data.message : i18n.t("Error occurred")));
    }
}

function* resetPassword({ payload }) {
    try {
        yield put(setLoading());
        yield call(apiResetPassword, { email: payload });
        delay(1000);
        yield put(setForgotPasswordPending());
        yield put(finishLoading());
    } catch (e) {
        console.error(e);
        yield put(authFailed((e.response && e.response.data) ? e.response.data.message : i18n.t("Error occurred")));
    }
}

function* confirmResetPassword({ payload }) {
    try {
        yield put(setLoading());
        yield call(apiConfirmPasswordReset, payload);
        yield put(authSuccess(i18n.t("Password has been reset successfully. Please log in.")));
        delay(1000);
        yield put(authSuccess());
        yield put(setRedirect("/auth/login"));
        yield put(finishLoading());
    } catch (e) {
        console.error(e);
        yield put(authFailed((e.response && e.response.data) ? e.response.data.message : i18n.t("Error occurred")));
    }
}

function* fetchUserIdAndRedirectUrl({ payload }) {
    try {
        yield put(setLoading());
        const result = yield call(apiGetCurrentUserId, payload);
        if (result.error)
            yield put(setUserIdResponse(result))
        else
            yield put(setUserIdResponse(result))
        yield put(finishLoading());
    } catch (e) {
        console.error('error while fetching');
        yield put(setUserIdResponse({ error: "no_auth" }));
        yield put(finishLoading());
    }
}

function* logUserOut() {
    try {
        yield call(apiLogUserOut);
        window.location.reload(false);
    } catch (e) { }
}

export default function* authenticationSaga() {
    yield takeEvery(SIGN_IN, signUserIn);
    yield takeEvery(SIGN_UP, signUserUp);
    yield takeEvery(VERIFY_ACCOUNT, verifyAccount);
    yield takeEvery(RESEND_CODE, resendCode);
    yield takeLatest(SET_TYPE, setAccountType);
    yield takeLatest(RESET_PASSWORD, resetPassword);
    yield takeLatest(CONFIRM_FORGOT_PASSWORD, confirmResetPassword);
    yield takeLatest(GET_USER_ID, fetchUserIdAndRedirectUrl);
    yield takeLatest(LOGOUT, logUserOut);
}