/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert
} from "reactstrap";
import { connect } from "react-redux";
import { registerUser } from "redux/actions/authentication-actions";
import CodeEntry from "components/General/auth-components/CodeEntry";
import { verifyAccount } from "redux/actions/authentication-actions";
import { clearAwaitsCode } from "redux/actions/authentication-actions";
import { authFailed } from "redux/actions/authentication-actions";
import { resendCode } from "redux/actions/authentication-actions";
import { clearRedirect } from "redux/actions/global-actions";
import { authSuccess } from "redux/actions/authentication-actions";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { setEmail } from "redux/actions/authentication-actions";
import logo from 'assets/img/brand/Rest cent logo large.png';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      repeatPassword: "",
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitVerificationCode = this.submitVerificationCode.bind(this);
    this.resendCode = this.resendCode.bind(this);
  }

  componentDidUpdate() {
    if (this.props.redirectUrl.length > 0) {
      this.props.history.push(this.props.redirectUrl);
      this.props.clearRedirect();
    }
    if (this.props.email.length > 0) {
      this.setState({ email: this.props.email });
      this.props.clearEmail();
    }
  }

  submitVerificationCode(code) {
    if (!this.state.email) {
      this.props.clearAwaitsCode();
    }
    this.props.verifyAccount(this.state.email, code);
  }

  resendCode() {
    if (!this.state.email)
      return this.props.authFailed("Something went wrong...");
    this.props.resendCode(this.state.email);
  }

  handleChange(event) {
    this.props.authFailed('');
    this.props.authSuccess('');
    this.setState({ [event.target.name]: event.target.value });
  }

  // validatePassword() {
  //   const pass = this.state.password;
  //   const repeat = this.state.repeatPassword;
  //   if (!pass || !repeat || pass !== repeat || !pass.length > 8)
  //     return false;
  //   return true;
  // }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.password !== this.state.repeatPassword) {
      this.props.authFailed(this.props.t("The entered passwords do not match"));
      return;
    }
    else if (this.state.password.length < 8) {
      this.props.authFailed(this.props.t("The minimum length of password is 8 characters"));
      return;
    }
    //TODO: Save account type as cookie for future call
    this.props.registerUser({ email: this.state.email, password: this.state.password });
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <img
              className="img-center"
              style={{ maxWidth: "50%", marginTop: 32 }}
              alt="..."
              src={logo}
            />
            <CardBody className="px-lg-5 py-lg-5">
              {this.props.awaitsCode ?
                <CodeEntry onSubmit={this.submitVerificationCode} resendCode={this.resendCode} />
                :
                <div>
                  <div className="text-center text-muted mb-4">
                    <h2>{this.props.t("Registration form")}</h2>
                  </div>
                  <Form role="form" id="registrationForm" onSubmit={this.handleSubmit}>
                    {/* <FormGroup>
                      <ButtonGroup style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                        <Button color="primary" onClick={() => { this.setState({ account_type: account_types.user }) }} active={this.state.account_type === account_types.user} style={{ flexGrow: 1 }}>User</Button>
                        <Button color="primary" onClick={() => { this.setState({ account_type: account_types.charity }) }} active={this.state.account_type === account_types.charity} style={{ flexGrow: 1 }}>Charity</Button>
                        <Button color="primary" onClick={() => { this.setState({ account_type: account_types.shop }) }} active={this.state.account_type === account_types.shop} style={{ flexGrow: 1 }}>Shop</Button>
                      </ButtonGroup>
                    </FormGroup> */}
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input required placeholder={this.props.t("E-mail address")} type="email" autoComplete="new-email" value={this.state.email} name="email" onChange={this.handleChange} />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input required placeholder={this.props.t("Password")} type="password" autoComplete="new-password" value={this.state.password} name="password" onChange={this.handleChange} />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input required placeholder={this.props.t("Repeat password")} type="password" autoComplete="new-repeat-password" value={this.state.repeatPassword} name="repeatPassword" onChange={this.handleChange} />
                      </InputGroup>
                    </FormGroup>
                    {/* <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div> */}
                    <Row className="my-4">
                      <Col xs="12">
                        <span className="text-muted">
                          {this.props.t('Detailed information about how we handle data can be found in our ')}
                          < a href="https://rest-cent.de/datenschutz" target="_blank" rel="noreferrer">
                            {this.props.t("data protection")}
                          </a>
                        </span>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button className="mt-4" color="primary" type="submit">
                        {this.props.t("Register")}
                      </Button>
                    </div>
                  </Form>
                </div>}
              {
                (this.props.errorMessage && this.props.errorMessage.length > 0 ?
                  <div className="mt-4">
                    <Alert color="danger"><strong>{this.props.errorMessage}</strong></Alert>
                  </div> : null)
              }
              {
                (this.props.successMessage && this.props.successMessage.length > 0 ?
                  <div className="mt-4">
                    <Alert color="success"><strong>{this.props.successMessage}</strong></Alert>
                  </div> : null)
              }
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    // successMessage: state.successMessage,
    errorMessage: state.authentication.errorMessage,
    successMessage: state.authentication.successMessage,
    awaitsCode: state.authentication.awaitsCode,
    redirectUrl: state.global.redirectUrl,
    email: state.authentication.email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: details => dispatch(registerUser(details)),
    verifyAccount: (email, code) => dispatch(verifyAccount({ email, code })),
    clearAwaitsCode: () => dispatch(clearAwaitsCode()),
    authFailed: err => dispatch(authFailed(err)),
    authSuccess: msg => dispatch(authSuccess(msg)),
    resendCode: email => dispatch(resendCode({ email })),
    clearRedirect: () => dispatch(clearRedirect()),
    clearEmail: () => dispatch(setEmail("")),
  };
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Register);
