import { spawn } from 'redux-saga/effects'
import authenticationSaga from './authentication-saga'
import dashboardSaga from './dashboard-saga';
import shopEntitySaga from './shop-entity-sagas';
import donationEntitySaga from './donation-entity-sagas';

// Export the root saga
export default function* rootSaga() {
    yield spawn(authenticationSaga);
    yield spawn(dashboardSaga);
    yield spawn(shopEntitySaga);
    yield spawn(donationEntitySaga);
}