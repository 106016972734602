/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
  Alert,
  ButtonGroup,
  CardImg,
  CardTitle,
  CardText
} from "reactstrap";
import { connect } from "react-redux";
import { authFailed } from "redux/actions/authentication-actions";
import { clearRedirect } from "redux/actions/global-actions";
import { authSuccess } from "redux/actions/authentication-actions";
import charity_img from 'assets/img/rest-cent/charity.png';
import shop_img from 'assets/img/rest-cent/shop.png';
import user_img from 'assets/img/rest-cent/user.png';
import { setAccountType } from "redux/actions/authentication-actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
const account_types = {
  charity: "CHARITY",
  user: "USER",
  shop: "SHOP"
}

const cardStyle = {
  cursor: 'pointer'
}

class TypeSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account_type: 0
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate() {
    if (this.props.redirectUrl && this.props.redirectUrl.length > 0) {
      this.props.history.push(this.props.redirectUrl);
      this.props.clearRedirect();
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if (this.state.account_type === 0) {
      this.props.authFailed(this.props.t("You must select account type to proceed."));
      return;
    }
    this.props.setAccountType(this.state)
  }

  render() {
    return (
      <>
        <Col md="12">
          <Card className="bg-secondary shadow border-0" id="type-selection">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h2>{this.props.t("Select account type")}</h2>
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup>
                  <ButtonGroup>
                    <Row>
                      <Col md="4">
                        <Card className={this.state.account_type === account_types.charity ? "selected" : ""}
                          onClick={() => { this.setState({ account_type: account_types.charity }) }}
                          style={cardStyle}>
                          <CardImg
                            height="200px"
                            alt={this.props.t("Charity acc type text")}
                            src={charity_img}
                            middle
                          />
                          <CardBody className="text-center">
                            <CardTitle>{this.props.t("Charity")}</CardTitle>
                            <CardText style={{ minHeight: 100 }}>
                              {this.props.t("Charity acc type text")}
                            </CardText>
                            {/* <div>
                              <Button
                                color="primary"
                                onClick={() => { this.setState({ account_type: account_types.charity }) }}
                                active={this.state.account_type === account_types.charity}>{this.props.t("Then, this way!")}</Button>
                            </div> */}
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className={this.state.account_type === account_types.user ? "selected" : ""}
                          onClick={() => { this.setState({ account_type: account_types.user }) }}
                          style={cardStyle}>
                          <CardImg
                            height="200px"
                            alt={this.props.t("User acc type text")}
                            src={user_img}
                            middle
                          />
                          <CardBody className="text-center">
                            <CardTitle>{this.props.t("User")}</CardTitle>
                            <CardText style={{ minHeight: 100 }}>
                              {this.props.t("User acc type text")}
                            </CardText>
                            {/* <div>
                              <Button
                                color="primary"
                                onClick={() => { this.setState({ account_type: account_types.user }) }}
                                active={this.state.account_type === account_types.user}>{this.props.t("Then, this way!")}</Button>
                            </div> */}
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className={this.state.account_type === account_types.shop ? "selected" : ""}
                          onClick={() => { this.setState({ account_type: account_types.shop }) }}
                          style={cardStyle}>
                          <CardImg
                            height="200px"
                            alt={this.props.t("Shop acc type text")}
                            src={shop_img}
                            bottom
                          />
                          <CardBody className="text-center">
                            <CardTitle>{this.props.t("Shop Owner")}</CardTitle>
                            <CardText style={{ minHeight: 100 }}>
                              {this.props.t("Shop acc type text")}
                            </CardText>
                            {/* <div>
                              <Button
                                color="primary"
                                onClick={() => { this.setState({ account_type: account_types.shop }) }}
                                active={this.state.account_type === account_types.shop}>{this.props.t("Then, this way!")}</Button>
                            </div> */}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    {/* <Button color="primary" onClick={() => { this.setState({ account_type: account_types.user }) }} active={this.state.account_type === account_types.user} style={{ flexGrow: 1 }}>User</Button>
                    <Button color="primary" onClick={() => { this.setState({ account_type: account_types.charity }) }} active={this.state.account_type === account_types.charity} style={{ flexGrow: 1 }}>Charity</Button>
                    <Button color="primary" onClick={() => { this.setState({ account_type: account_types.shop }) }} active={this.state.account_type === account_types.shop} style={{ flexGrow: 1 }}>Shop</Button> */}
                  </ButtonGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="success" type="submit">
                    {this.props.t("Confirm")}
                  </Button>
                </div>
              </Form>
              {
                (this.props.errorMessage && this.props.errorMessage.length > 0 ?
                  // <div className="mt-4">
                  <Alert color="danger"><strong>{this.props.errorMessage}</strong></Alert>
                  // </div> 
                  : null)
              }
              {
                (this.props.successMessage && this.props.successMessage.length > 0 ?
                  // <div className="mt-4">
                  <Alert color="success"><strong>{this.props.successMessage}</strong></Alert>
                  // </div> 
                  : null)
              }
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="https://rest-cent.de"
              // onClick={e => e.preventDefault()}
              >
                <small>{this.props.t("More information")}</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.authentication.errorMessage,
    successMessage: state.authentication.successMessage,
    redirectUrl: state.global.redirectUrl
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authFailed: err => dispatch(authFailed(err)),
    authSuccess: msg => dispatch(authSuccess(msg)),
    clearRedirect: () => dispatch(clearRedirect()),
    setAccountType: type => dispatch(setAccountType(type))
  };
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(TypeSelection);
