import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Modal, CardBody, Form, Card } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import InfoWithTooltip from 'components/Small/InfoWithTooltip';
import { dashboardInitialState } from 'redux/reducers/dashboard-reducers';
import AccountSettings from './Account-settings';
import { fetchBankAccounts, updateBankAccount } from 'redux/actions/dashboard-actions';
function CharityBankSettings({ t, onChange, settings, bank_accounts, fetchBankAccounts, updateBankAccount }) {
    const [showData, setShowData] = useState(false);
    const [mainBankAccount, setMainBankAccount] = useState(null);
    const [mainChanged, setMainChanged] = useState(false);
    const [commissionBankAccount, setCommissionBankAccount] = useState(null);
    const [commChanged, setCommChanged] = useState(false);

    useEffect(() => {
        if (showData) {
            fetchBankAccounts();
            setMainChanged(false);
            setCommChanged(false);
        }
    }, [showData, fetchBankAccounts]);

    useEffect(() => {
        setMainBankAccount(assignInitialSettings(bank_accounts.find(ba => ba.account_name === "MAIN")));
        const commissionAccount = assignInitialSettings(bank_accounts.find(ba => ba.account_name === "COMMISSION"));
        if (commissionAccount)
            setCommissionBankAccount(commissionAccount);
    }, [bank_accounts]);

    const assignInitialSettings = (objToAssign) => {
        let obj = Object.assign({}, dashboardInitialState.bank_accounts[0]);
        console.log(objToAssign)
        if (!objToAssign)
            return null;
        for (let key in obj) {
            obj[key] = objToAssign[key]
        }
        return obj;
    }

    const updateBankDetails = () => {
        // check if something got changed
        setShowData(false);
        if (mainChanged)
            updateBankAccount(mainBankAccount);
        if (commChanged)
            updateBankAccount(commissionBankAccount);
        // trigger change for that something
    }

    const onMainAccountChange = evt => {
        // account_name = 'main';
        setMainBankAccount({ ...mainBankAccount, [evt.target.name]: evt.target.value });
        setMainChanged(true);
    }

    const onCommissionAccountChange = evt => {
        // account_name = 'commission';
        setCommissionBankAccount({ ...commissionBankAccount, [evt.target.name]: evt.target.value });
        setCommChanged(true);
    }

    const onDefaultCommAccountCheckboxChange = evt => {
        // commission_account should be set to MAIN or COMM
        const updateObj = {
            target: {
                name: "commission_account",
                value: evt.target.checked ? "COMMISSION" : "MAIN"
            }
        }
        onChange(updateObj);
        // What if there's no commission account?
        // if commission account doesn't exist, let's create it!    
        if (!commissionBankAccount)
            setCommissionBankAccount(assignInitialSettings({}));
    };

    return (<>
        <h6 className="heading-small text-muted mb-4">
            {t("Bank Details")}
        </h6>
        {showData ?
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={true}
                toggle={() => setShowData(!showData)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <h3>{t("Bank Details")}</h3>
                            </div>
                            <Form role="form">
                                {/* Main account */}
                                <AccountSettings bank_account={mainBankAccount} onChange={onMainAccountChange} />
                                {/* End of main account */}
                                {!settings.auto_comm_subtract ?
                                    <><FormGroup>
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input
                                                className="custom-control-input"
                                                id="diff_comm_account"
                                                checked={settings.commission_account !== "MAIN"}
                                                name="diff_comm_account"
                                                type="checkbox"
                                                onChange={onDefaultCommAccountCheckboxChange} />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="diff_comm_account">
                                                {t("Use a different account for commission")}</label><InfoWithTooltip id="auto_comm_subtr" text={t("Different account for commission explanation")} />
                                        </div>
                                    </FormGroup>
                                        {settings.commission_account !== "MAIN" ?
                                            <>
                                                <div className="text-center text-muted mb-4">
                                                    <h3>{t("Commission Bank Details")}</h3>
                                                </div>
                                                <AccountSettings bank_account={commissionBankAccount} onChange={onCommissionAccountChange} />
                                            </> : null
                                        }

                                    </>
                                    : null}

                                <div className="text-center">
                                    <Button
                                        className="my-4"
                                        color="success"
                                        type="button"
                                        onClick={() => updateBankDetails()}
                                    >{t("Save")}</Button>
                                    <Button
                                        className="my-4"
                                        color="secondary"
                                        type="button"
                                        onClick={() => setShowData(false)}
                                    >{t("Cancel")}</Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            // <div className="pl-lg-4">
            //     <Row>
            //         <Col>
            //             <FormGroup>
            //                 <label
            //                     className="form-control-label"
            //                     htmlFor="name">
            //                     {t("Bank name")}
            //                 </label>
            //                 <Input
            //                     className="form-control-alternative"
            //                     id="bank_name"
            //                     placeholder={t("Bank name")}
            //                     name="bank_name"
            //                     onChange={onChange}
            //                     value={bank_account.bank_name}
            //                     type="text"
            //                 />
            //             </FormGroup>
            //         </Col>
            //         <Col>
            //             <FormGroup>
            //                 <label
            //                     className="form-control-label"
            //                     htmlFor="name">
            //                     {t("IBAN")}
            //                 </label>
            //                 <Input
            //                     className="form-control-alternative"
            //                     id="iban"
            //                     placeholder={t("IBAN")}
            //                     name="iban"
            //                     onChange={onChange}
            //                     value={bank_account.iban}
            //                     type="text"
            //                 />
            //             </FormGroup>
            //         </Col>
            //         <Col>
            //             <FormGroup>
            //                 <label
            //                     className="form-control-label"
            //                     htmlFor="name">
            //                     {t("BIC")}
            //                 </label>
            //                 <Input
            //                     className="form-control-alternative"
            //                     id="bic"
            //                     placeholder={t("bic")}
            //                     name="bic"
            //                     onChange={onChange}
            //                     value={bank_account.bic}
            //                     type="text"
            //                 />
            //             </FormGroup>
            //         </Col>
            //     </Row>
            // </div>
            :
            <div className="text-center">
                <Button style={{ marginBottom: 24 }} outline color="warning" className="btn-icon btn-3" type="button" onClick={() => setShowData(true)} >
                    <i style={{ fontSize: "5em", margin: 24 }} className="fas fa-lock" ></i>
                </Button>
                <h2>{t("Your bank details are hidden")}</h2>
                <p className="lead">
                    {t("To protect your sensitive information, your bank details are hidden by default. If you wish to see or change them, click on the lock icon above")}
                </p>
                {/* <Button className="btn-icon btn-3" color="success" type="button" onClick={() => setShowData(true)} >
                    <span className="btn-inner--icon">
                        <i className="ni ni-lock-circle-open" />
                    </span>
                    <span className="btn-inner--text">{t("Reveal")}</span>
                </Button> */}
            </div >
        }
    </>)
}
const mapStateToProps = state => {
    return {
        bank_accounts: state.dashboard.bank_accounts
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchBankAccounts: () => dispatch(fetchBankAccounts()),
        updateBankAccount: bank_account => dispatch(updateBankAccount(bank_account))
        // updateBankDetails: bank_details => dispatch(updateBankDetails(bank_details))

    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CharityBankSettings)