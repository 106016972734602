import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Input, Table, Media, Button, UncontrolledPopover, PopoverBody, PopoverHeader } from "reactstrap";
import { connect } from 'react-redux';
import styled from 'styled-components';

import { fetchFavourites } from 'redux/actions/dashboard-actions';
import { deleteFavourite } from 'redux/actions/dashboard-actions';
import { addFavourite } from 'redux/actions/dashboard-actions';
import { findCharities } from 'redux/actions/dashboard-actions';
import { setCharities } from 'redux/actions/dashboard-actions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import DataTable from 'components/DataDisplay/DataTable';
var timeout;

function debounce(func, wait, immediate) {
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

const MakeAndRemoveFav = ({cell, row, favourites, deleteFavourite, addFavourite}) => {
    const [fav, setFav] = useState(null);
    useEffect(() => {
        let f = favourites.find(f => f.charity.id === row.id);
        setFav(f)
    } , [])
    
    return (
        <CenterizeElments>
            {fav ?
                <Button
                 color="warning" 
                 size="sm"
                 onClick={() => {
                   deleteFavourite(fav.id);
                   setFav(null)
                 }}
                 title="Remove from Fav"
                >
                    <i className="fa fa-trash-alt" />
                </Button>
                :
                <Button
                 size="sm"
                 color="success" 
                 onClick={() => {
                    addFavourite(row.id);
                    setFav(row)
                 }}
                 title="Add to Fav"
                >
                    <i className="fa fa-star" />
                </Button>
            }
        </CenterizeElments>
    )
}



function FavouritesSelection({ t, favourites, deleteFavourite, fetchFavourites, addFavourite, findCharities, charities, setCharities }) {
    const [search, setSearch] = useState("");
    // var debouncedSearch = debounce(function () {
    //     findCharities(search);
    // }, 500);
    var debouncedSearch = debounce(function () {
        findCharities({page: 1, totalSize: 25, sizePerPage: 25, search});
    }, 500);

    const columns = ({ t }) => ([
    {
        text: "ID",
        dataField: "id",
        hidden: true
    }, 
    {
        text: t("Charity"),
        dataField: "name",
        formatter: (cell, row) => {
            return (
                <Media className="align-items-center">
                    <a
                        className="avatar avatar-sm rounded-circle mr-3"
                        href={"//" + row.website_url}
                        target="_blank"
                        rel="noreferrer">
                        <CircleImage
                            alt={row.name}
                            src={row.logo_url} 
                        />
                    </a>
                    <Media>
                        <span className="mb-0 text-sm">{row.name}</span>
                    </Media>
                </Media>
            )
        }
    }, 
    {
        text: t("Category"),
        dataField: "category",
        formatter: (cell, row) => {
            return (
                <CenterizeElments>
                    <span>{row.category}</span>
                </CenterizeElments>
            );
        }
    }, 
    {
        text: t("Website"),
        dataField: "website_url",
        formatter: (cell, row) => {
            return (
                <CenterizeElments>
                    <span>{row.website_url}</span>
                </CenterizeElments>
            );
        }
    }, 
    {
        text: t("Favourite"),
        dataField: "id",
        formatter: (cell, row) => (
            <MakeAndRemoveFav 
                cell={cell} 
                row={row} 
                favourites={favourites} 
                deleteFavourite={deleteFavourite} 
                addFavourite={addFavourite}
            />
        )
    }, 
    ]);

    const onSearch = input => {
        setSearch(input);
        // if (input.length > 0) {
        debouncedSearch();
        // }
        setCharities([]);
    };
    useEffect(() => {
        fetchFavourites();
    }, [fetchFavourites]);

    return (<>
        <div>
            <Row>
                <Col lg="12">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-redirect_url">{t("Search Charities")}</label>
                        <Input
                            className="form-control-alternative"
                            placeholder={t("Enter name or category")}
                            id="search-charities"
                            onChange={evt => onSearch(evt.target.value)}
                            onClick={() => onSearch("")}
                            value={search}
                            name="search-charities"
                            type="text" />
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <UncontrolledPopover placement="top" target="search-charities" isOpen={charities.length > 0} className="popover-container">
            <PopoverBody>
                <PopoverHeader style={{ display: "flex", justifyContent: "space-between" }}>
                    {t("Search results")}
                    <Button size="sm" outline id="closePopOver" onClick={() => { setSearch(""); setCharities([]); }}><i className="fa fa-times" /></Button>
                </PopoverHeader>
                <DataTable tableId="charity_table"
                    // isSearchEnabled
                    columns={columns({ t })}
                    pagingConfig={ {page: 1, totalSize: 25, sizePerPage: 25} }
                    fetchData={findCharities}
                    data={charities}
                    fetchFavourites={fetchFavourites} 
                />
            </PopoverBody>
        </UncontrolledPopover>
        <Table className="align-items-center" responsive>
            <thead className="thead-light">
                <tr>
                    <th scope="col">{t("Charity")}</th>
                    <th scope="col">{t("Category")}</th>
                    <th scope="col">{t("Website")}</th>
                    <th scope="col">{t("Favourite")}</th>
                </tr>
            </thead>
            <tbody>
                {favourites.map( fav => (
                    <tr key={fav.id}>
                        <th scope="row">
                            <Media className="align-items-center">
                                <a
                                    className="avatar rounded-circle mr-3"
                                    href={"//" + fav.charity.website_url}
                                    target="_blank"
                                    rel="noreferrer">
                                    <CircleImage
                                        alt={fav.charity.name}
                                        src={fav.charity.logo_url}
                                    />
                                </a>
                                <Media>
                                    <span className="mb-0 text-sm">{fav.charity.name}</span>
                                </Media>
                            </Media>
                        </th>
                        <td>
                            {fav.charity.category}
                        </td>
                        <td>
                            <a href={"//" + fav.charity.website_url} target="_blank" rel="noreferrer">{fav.charity.website_url}</a>
                        </td>
                        <td>
                            <Button className="my-4" color="warning" onClick={() => deleteFavourite(fav.id)}><i className="fa fa-trash-alt" /></Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </>)
}

const mapStateToProps = state => {
    return {
        favourites: state.dashboard.favourites,
        searchedFavourites: state.dashboard.searchedFavourites,
        charities: state.dashboard.charities
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchFavourites: () => dispatch(fetchFavourites()),
        deleteFavourite: id => dispatch(deleteFavourite(id)),
        addFavourite: id => dispatch(addFavourite(id)),
        // findCharities: input => dispatch(findCharities(input)),
        findCharities: queryObj => dispatch(findCharities({queryObj})),
        setCharities: chars => dispatch(setCharities(chars))
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(FavouritesSelection);

const CircleImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`
const CenterizeElments = styled.div`
    width: 100%;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`