import { SIGN_IN } from "redux/constants/authentication-constants";
import { SIGN_UP } from "redux/constants/authentication-constants";
import { AUTH_FAILED } from "redux/constants/authentication-constants";
import { AUTH_SUCCESS } from "redux/constants/authentication-constants";
import { VERIFY_ACCOUNT } from "redux/constants/authentication-constants";
import { SET_AWAITS_CODE } from "redux/constants/authentication-constants";
import { CLEAR_AWAITS_CODE } from "redux/constants/authentication-constants";
import { RESEND_CODE } from "redux/constants/authentication-constants";
import { SET_SIGNEDIN } from "redux/constants/authentication-constants";
import { LOGOUT } from "redux/constants/authentication-constants";
import { UPDATE_ACCOUNT } from "redux/constants/dashboard-constants";
import { SET_TYPE } from "redux/constants/authentication-constants";
import { RESET_PASSWORD } from "redux/constants/authentication-constants";
import { SET_RESET_PENDING } from "redux/constants/authentication-constants";
import { CONFIRM_FORGOT_PASSWORD } from "redux/constants/authentication-constants";
import { SET_EMAIL } from "redux/constants/authentication-constants";
import { GET_USER_ID } from "redux/constants/authentication-constants";
import { SET_USER_ID_RESPONSE } from "redux/constants/authentication-constants";

export function authenticateUser(payload) {
    return { type: SIGN_IN, payload };
}
export function registerUser(payload) {
    return { type: SIGN_UP, payload };
}
export function authFailed(payload) {
    return { type: AUTH_FAILED, payload }
}
export function authSuccess(payload) {
    return { type: AUTH_SUCCESS, payload }
}
export function verifyAccount(payload) {
    return { type: VERIFY_ACCOUNT, payload };
}
export function setAwaitsCode() {
    return { type: SET_AWAITS_CODE };
}
export function clearAwaitsCode() {
    return { type: CLEAR_AWAITS_CODE };
}
export function resendCode(payload) {
    return { type: RESEND_CODE, payload };
}
export function setSignedIn(payload) {
    return { type: SET_SIGNEDIN, payload };
}
export function logOut() {
    return { type: LOGOUT };
}
export function updateAccount(payload) {
    return { type: UPDATE_ACCOUNT, payload }
}
export function setAccountType(payload) {
    return { type: SET_TYPE, payload }
}
export function forgotPassword(payload) {
    return { type: RESET_PASSWORD, payload }
}
export function setForgotPasswordPending() {
    return { type: SET_RESET_PENDING }
}
export function confirmForgotPassword(payload) {
    return { type: CONFIRM_FORGOT_PASSWORD, payload }
}
export function setEmail(payload) {
    return { type: SET_EMAIL, payload }
}
export function getUserId(payload) {
    return { type: GET_USER_ID, payload }
}
export function setUserIdResponse(payload) {
    return { type: SET_USER_ID_RESPONSE, payload }
}