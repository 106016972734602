export const FETCH_ACCOUNT = 'FETCH_ACCOUNT';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SET_SETTINGS = 'SET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const FETCH_FAVOURITES = 'FETCH_FAVOURITES';
export const DELETE_FAVOURITE = 'DELETE_FAVOURITE';
export const FIND_CHARITIES = 'FIND_CHARITIES';
export const SET_CHARITIES = 'SET_CHARITIES';
export const ADD_FAVOURITE = 'ADD_FAVOURITE';
export const SET_FAVOURITES = 'SET_FAVOURITES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ADDITIONAL_CATEGORIES = 'SET_ADDITIONAL_CATEGORIES';
export const UPLOAD_LOGO = 'UPLOAD_LOGO';
export const UPLOAD_DZI = 'UPLOAD_DZI';
export const UPLOAD_PROOF = 'UPLOAD_PROOF';
export const FETCH_BANK_ACCOUNTS = 'FETCH_BANK_ACCOUNTS';
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const FETCH_PLATFORMS = 'FETCH_PLATFORMS';
export const SET_PLATFORMS = 'SET_PLATFORMS'
export const GET_DIRECTDEBIT_SETUP_INTENT = 'GET_DIRECTDEBIT_SETUP_INTENT';
export const SET_DIRECTDEBIT_SETUP_INTENT = 'SET_DIRECTDEBIT_SETUP_INTENT';
export const FETCH_CHARITY_VIS_RULES = 'FETCH_CHARITY_VIS_RULES';
export const SET_CHARITY_VIS_RULES = 'SET_CHARITY_VIS_RULES';
export const ADD_CHARITY_VIS_RULE = 'ADD_CHARITY_VIS_RULE';
export const DELETE_CHARITY_VIS_RULE = 'DELETE_CHARITY_VIS_RULE';