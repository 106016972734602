import React from 'react';
import { Row, Col } from "reactstrap";
import { withTranslation } from 'react-i18next';
import InfoWithTooltip from 'components/Small/InfoWithTooltip';

function SettingsSettings({ t, onChange, settings }) {

    return (<>
        <h6 className="heading-small text-muted mb-4">
            {t("E-mail communication")}
        </h6>
        <div className="pl-lg-4">
            <Row>
                <Col lg="12">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="customCheck1"
                            type="checkbox"
                            checked={settings.monthly_stats}
                            name="monthly_stats"
                            onChange={onChange}
                        />
                        <label className="custom-control-label" htmlFor="customCheck1">{t("Monthly Statistics")}</label>
                    </div>
                </Col>
                <Col lg="12">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="newsletter"
                            type="checkbox"
                            checked={settings.newsletter}
                            name="newsletter"
                            onChange={onChange}
                        />
                        <label className="custom-control-label" htmlFor="newsletter">{t("Newsletter")}</label><InfoWithTooltip id="newsletters_info" text={t("Newsletter explanation")} />
                    </div>
                </Col>
            </Row>
        </div>
    </>)
}

export default withTranslation()(SettingsSettings)