import axios from './axios-client';

// Sign the user in 
export const apiSignIn = async (details) => {
    const signInResult = await axios.post('auth/authenticate', details);
    return signInResult;
}

// Sign the user up
export const apiSignUp = async (details) => {
    const signUpResult = await axios.post('auth/register', details);
    return signUpResult;
}

// Verify user account
export const apiVerifyAccount = async (details) => {
    const verifyResult = await axios.post('auth/verify', details);
    return verifyResult;
}

export const apiSelectAccountType = async (details) => {
    const result = await axios.post('auth/acctype', details);
    return result;
}

export const apiResendCode = async (details) => {
    const result = await axios.post('auth/resend', details);
    return result;
}

export const apiResetPassword = async (details) => {
    const result = await axios.post('auth/forgot', details);
    return result;
}

export const apiConfirmPasswordReset = async (details) => {
    const result = await axios.post('auth/confirmforgot', details);
    return result;
}

//Get current users id
export const apiGetCurrentUserId = async (payload) => {
    let url = "auth/id/" + payload.shopId;
    if (payload.shopRedirect)
        url += "?redirect=" + payload.shopRedirect;
    const result = await axios.get(url);
    return result.data;
}

export const apiLogUserOut = async () => {
    const result = await axios.post("auth/logout");
    return result.data;
}