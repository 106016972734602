import React from 'react';
import { Row, Col, FormGroup, Input } from "reactstrap";
import { withTranslation } from 'react-i18next';

function DonatorProfile({ entity, onChange, settings, t }) {

    return (<>
        <h6 className="heading-small text-muted mb-4">
            {t("General information")}
        </h6>
        <Row>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name">
                        {t("First name")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-first-name"
                        placeholder={t("First name")}
                        name="contact_name"
                        onChange={onChange}
                        value={entity.contact_name}
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-last-name">
                        {t("Last name")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-last-name"
                        placeholder={t("Last name")}
                        name="contact_surname"
                        onChange={onChange}
                        value={entity.contact_surname}
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name">
                        {t("Phone number")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-first-name"
                        placeholder={t("Phone number")}
                        name="contact_phone"
                        onChange={onChange}
                        value={entity.contact_phone}
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name">
                        {t("Website")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-first-name"
                        placeholder={t("Website")}
                        name="website_url"
                        onChange={onChange}
                        value={entity.website_url}
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-address">
                        {t("Street")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-address"
                        placeholder={t("Street")}
                        onChange={onChange}
                        value={entity.contact_street}
                        name="contact_street"
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-city">
                        {t("City")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-city"
                        placeholder={t("City")}
                        onChange={onChange}
                        value={entity.contact_city}
                        name="contact_city"
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-country">
                        {t("Postal code")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-postal-code"
                        onChange={onChange}
                        value={entity.contact_plz}
                        name="contact_plz"
                        placeholder={t("Postal code")}
                        type="number"
                    />
                </FormGroup>
            </Col>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-country">
                        {t("Country")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-country"
                        readOnly={true}
                        onChange={onChange}
                        value={entity.contact_country}
                        name="contact_country"
                        placeholder={t("Country")}
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-fb">
                        {t("Facebook URL")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-fb"
                        onChange={onChange}
                        value={entity.social_facebook_url}
                        name="social_facebook_url"
                        placeholder={t("Facebook URL")}
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col lg="6">
                <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-insta">
                        {t("Instagram URL")}
                    </label>
                    <Input
                        className="form-control-alternative"
                        id="input-insta"
                        onChange={onChange}
                        value={entity.social_instagram_url}
                        name="social_instagram_url"
                        placeholder={t("Instagram URL")}
                        type="text"
                    />
                </FormGroup>
            </Col>
        </Row>
    </>);
}
export default withTranslation()(DonatorProfile);