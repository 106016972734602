export const SET_LOADING = "SET_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";
export const SET_REDIRECT = "SET_REDIRECT";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_UPLOADED_FILE_URL = 'SET_UPLOADED_FILE_URL';

// Routes
export const AUTH_LOGIN = '/auth/login';
export const AUTH_REGISTER = '/auth/register';
export const DASHBOARD_HOME = '/dashboard';