import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;
        const sessionExpiry = new Date(localStorage.getItem("sessionExpiry"));
        const accType = localStorage.getItem("accType");
        const isAuthenticated = sessionExpiry > new Date();
        if (!isAuthenticated) {
            return <Redirect to={{ pathname: '/auth/login' }} />
        } else if ((accType === "null" || !accType) && !this.props.path.includes("typeselection")) {
            return <Redirect to={{ pathname: '/auth/typeselection' }} />
        } else {
            return <Component {...this.props} />
        }
    }
}

const mapStateToProps = (state) => {
    return {
        sessionExpiry: state.authentication.sessionExpiry
    }
}

export default connect(mapStateToProps)(ProtectedRoute);