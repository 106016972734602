import React from 'react';
import { BtnWrapper, Button } from './ButtonStyles';

export const ToogleButton = ({
    btn1Title, 
    btn2Title, 
    handleOnChangeOff, 
    handleOnChangeOn, 
    active,
    t
}) => {
    return (
        <BtnWrapper>
            <Button 
                style={active ? {} : activeStyles} 
                type="button"
                onClick={handleOnChangeOff}
            >
                {t(btn1Title)}
            </Button >
            <Button 
                style={active ? activeStyles : {}} 
                type="button"
                onClick={handleOnChangeOn}
                id="btnOn"
            >
                {t(btn2Title)}
            </Button >
        </BtnWrapper>
    )
}

const activeStyles = {
    color: "rgb(255 , 255 , 255 , .9)",
    letterSpacing: "0.2px",
    background: "#5e72e4",
    borderRadius: "14px",
    boxShadow: "-3px 0 7px 3px rgba(0, 0, 0, 0.06)",
}
