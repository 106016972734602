import DataTable from 'components/DataDisplay/DataTable';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Container, Input, Row, Modal, Button, Col, Form, FormGroup } from 'reactstrap';
import { compose } from 'redux';
import { fetchCategories } from 'redux/actions/dashboard-actions';
import { deleteCharityVisRule } from 'redux/actions/dashboard-actions';
import { addCharityVisRule } from 'redux/actions/dashboard-actions';
import { fetchCharityVisRules } from 'redux/actions/dashboard-actions';
import CharityVisibilityDonatorSearch from './Charity-visibility-donator-search';
import { setErrorMessage } from 'redux/actions/global-actions';

const mapStateToProps = state => {
    return {
        charityVisibilityRules: state.dashboard.charityVisibilityRules,
        charityVisibilityRulesQueryObj: state.dashboard.charityVisibilityRulesQueryObj,
        additionalCategories: state.dashboard.additionalCategories,
        account: state.dashboard.account
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCategories: (type) => dispatch(fetchCategories(type)),
        setErrorMessage: msg => dispatch(setErrorMessage(msg)),
        fetchCharityVisibilityRules: (charityId, queryObj) => dispatch(fetchCharityVisRules({ charityId, queryObj })),
        addCharityVisibilityRule: (charityId, rule) => dispatch(addCharityVisRule({ charityId, rule })),
        deleteCharityVisibilityRule: (charityId, ruleId) => dispatch(deleteCharityVisRule({ charityId, ruleId }))
    }
}
const connector = compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps));

const columns = ({ deleteCharityVisibilityRule, charityId, t }) => ([{
    text: "ID",
    dataField: "id",
    hidden: true
}, {
    text: t("Behaviour"),
    dataField: "behaviour",
    sort: true,
    headerStyle: (column, colIndex) => ({ width: "15%", textAlign: "center" }),
    formatter: cell =>
        <div className="text-center">
            {cell === "BLOCK" ?
                <i style={{ color: "red" }} className="fas fa-times" title={t("Reject donations")} />
                : <i style={{ color: "green" }} className="fas fa-check" title={t("Accept donations")} />}
        </div>
    ,
    align: "center",
}, {
    text: t("Rule Type"),
    dataField: "object_type",
    sort: true,
    headerStyle: (column, colIndex) => ({ width: "15%", textAlign: "center" }),
    formatter: cell =>
        <div className="text-center">
            {cell === "DONATOR" ?
                <i className="fas fa-store-alt" title={t("Donator")} />
                : <i className="fas fa-boxes" title={t("Category")} />}
        </div>
},
{
    text: t("Subject"),
    dataField: "object_id",
    headerStyle: (column, colIndex) => ({ width: "55%" }),
    formatter: (cell, row) =>
        row.object_type === "DONATOR" ?
            <a href={"//" + row.website_url} target="_blank" rel="noreferrer"><span>{row.object_name}</span></a>
            : <span>{row.object_name}</span>
}, {
    text: t("Action"),
    dataField: 'df1',
    isDummyField: true,
    headerStyle: (column, colIndex) => ({ width: "15%", textAlign: "center" }),
    formatter: (cell, row) =>
        <div className="text-center">
            <Button size="sm" className="btn-icon btn-2" color="warning" type="button" onClick={() => { deleteCharityVisibilityRule(charityId, row.id) }}>
                <i className="fas fa-trash" />
            </Button>
        </div>
}]);

const AddNewDialog = props => {
    const [object_type, setObjType] = useState("");
    const [behaviour, setBehaviour] = useState("");
    const [object_id, setObjectId] = useState("");
    const onSubmit = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (object_type.length === 0 || behaviour.length === 0 || object_id.length === 0) {
            props.setErrorMessage(props.t("Please fill in all fields"));
            return
        }
        props.addNew({ object_type, behaviour, object_id })
        props.setShowAddNewDialog(false);
    };
    const areOptionsLimited = (val) => {
        const approach = props.account.visibility_approach;
        //if blacklist, cannot allow categories (they are allowed by default)
        //if whitelist, cannot block categories (they are blocked by default)
        switch (val) {
            case "ALLOW":
                return object_type === "CATEGORY" && approach === "BLACKLIST";
            case "BLOCK":
                return object_type === "CATEGORY" && approach === "WHITELIST"
            case "CATEGORY":
                return ((behaviour === "BLOCK" && approach === "WHITELIST") || (behaviour === "ALLOW" && approach === "BLACKLIST"))
            default:
                return false;
        }
    }

    return (
        <Container id="addNewcontainer" style={{ border: "1px solid var(--primary)", borderRadius: 4, padding: 8, marginTop: 8, marginBottom: 8 }}>
            <div className="text-center">
                <h3>{props.t("Add New Item")}</h3>
            </div>
            <Form onSubmit={onSubmit}>
                <Row>
                    <Col lg="4" >
                        <FormGroup>
                            <label
                                className="form-control-label small"
                                htmlFor="input-behaviour">{props.t("Behaviour")}</label>
                            <Input
                                bsSize="sm"
                                className="form-control-alternative"
                                id="input-behaviour"
                                placeholder={props.t("Behaviour")}
                                onChange={evt => setBehaviour(evt.target.value)}
                                value={behaviour}
                                type="select">
                                <option key={""} value={""}>{""}</option>
                                <option disabled={areOptionsLimited("BLOCK")} key={"BLOCK"} value={"BLOCK"}>{props.t("Block donations")}</option>
                                <option disabled={areOptionsLimited("ALLOW")} key={"ALLOW"} value={"ALLOW"}>{props.t("Allow donations")}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col lg="4"  >
                        <FormGroup>
                            <label
                                className="form-control-label small"
                                htmlFor="input-object_type">{props.t("Rule Type")}</label>
                            <Input
                                bsSize="sm"
                                className="form-control-alternative"
                                id="object_type"
                                placeholder={props.t("Rule Type")}
                                onChange={evt => setObjType(evt.target.value)}
                                value={object_type}
                                type="select">
                                <option key={""} value={""}>{""}</option>
                                <option key={"DONATOR"} value={"DONATOR"}>{props.t("Shop")}</option>
                                <option disabled={areOptionsLimited("CATEGORY")} key={"CATEGORY"} value={"CATEGORY"}>{props.t("Category")}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col lg="4" >
                        <FormGroup>
                            {object_type === "CATEGORY" ?
                                <>
                                    <label
                                        className="form-control-label small"
                                        htmlFor="input-object_id">{props.t("Subject")}</label>
                                    <Input
                                        disabled={object_type.length === 0 || behaviour.length === 0 ? true : false}
                                        bsSize="sm"
                                        className="form-control-alternative"
                                        id="input-object_id"
                                        placeholder={props.t("Subject")}
                                        name="object_id"
                                        onChange={evt => setObjectId(evt.target.value)}
                                        value={object_id}
                                        type="select">
                                        {props.additionalCategories.map(ac => <option key={ac.key} value={ac.key}>{ac.value}</option>)}
                                    </Input>
                                </>
                                :
                                <CharityVisibilityDonatorSearch disabled={object_type.length === 0 || behaviour.length === 0 ? true : false} setObject={obj_id => { setObjectId(obj_id) }} />
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <div className="text-center">
                    <Button color="success" size="sm" type="submit">{props.t("Save")}</Button>
                    <Button color="primary" size="sm" onClick={() => { props.setShowAddNewDialog(false) }}>{props.t("Cancel")}</Button>
                </div>
            </Form>
        </Container >
    )
}

const ConnectedAddNewDialog = connector(AddNewDialog);


function CharityVisibilityModal({ t,
    isOpen, toggleModal,
    visibilityApproach, charityId,
    fetchCharityVisibilityRules, addCharityVisibilityRule,
    deleteCharityVisibilityRule, charityVisibilityRules,
    charityVisibilityRulesQueryObj = { page: 1, totalSize: 25, sizePerPage: 25 },
    fetchCategories }) {
    const [showAddNewDialog, setShowAddNewDialog] = useState(false);
    useEffect(() => {
        if (charityId.length > 0) {
            fetchCharityVisibilityRules(charityId);
        }
    }, [fetchCharityVisibilityRules, charityId])

    const refetchData = queryObj => {
        fetchCharityVisibilityRules(charityId, queryObj);
    }
    useEffect(() => {
        fetchCategories("SHOP");
    }, [fetchCategories])

    return (
        <>
            <Modal
                id="whiteblacklistmodal"
                size="lg"
                className="modal-dialog-centered"
                isOpen={isOpen}
                toggle={() => toggleModal()}
            >
                <div className="modal-header">
                    <h4 className="modal-title" id="modal-title-default">
                        {t("Configure the visibility rules")}
                    </h4>
                    <button
                        aria-label={t("Close")}
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>

                </div>
                <div className="modal-body">
                    {
                        visibilityApproach === "WHITELIST" ?
                            <p>{t("You have selected WL")}</p>
                            :
                            <p>{t("You have selected BL")}</p>
                    }
                    <DataTable tableId="sample_tab"
                        isSearchEnabled
                        AddNewDialog={props => <ConnectedAddNewDialog addNew={obj => { props.addNew(charityId, obj) }}
                            setShowAddNewDialog={props.setShowAddNewDialog} />}
                        showAddNewDialog={showAddNewDialog}
                        setShowAddNewDialog={v => { setShowAddNewDialog(v) }}
                        columns={columns({ deleteCharityVisibilityRule, charityId, t })}
                        addNew={addCharityVisibilityRule}
                        pagingConfig={charityVisibilityRulesQueryObj}// TODO
                        fetchData={refetchData}
                        data={charityVisibilityRules}
                        addNewText={t("Add new rule")} />
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}>
                        {t("Close")}</Button>
                </div>
            </Modal>
        </>
    )
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CharityVisibilityModal)