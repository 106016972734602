import {
    put,
    call,
    takeEvery,
    takeLatest,
    select,
} from 'redux-saga/effects'
import { setLoading } from 'redux/actions/global-actions';
import { apiGetAccount } from 'redux/api/dashboard-api';
import { setAccount } from 'redux/actions/dashboard-actions';
import { FETCH_ACCOUNT } from 'redux/constants/dashboard-constants';
import { UPDATE_ACCOUNT } from 'redux/constants/dashboard-constants';
import { finishLoading } from 'redux/actions/global-actions';
import { apiPutAccount } from 'redux/api/dashboard-api';
import { setSuccessMessage } from 'redux/actions/global-actions';
import { setErrorMessage } from 'redux/actions/global-actions';
import { FETCH_SETTINGS } from 'redux/constants/dashboard-constants';
import { UPDATE_SETTINGS } from 'redux/constants/dashboard-constants';
import { apiGetSettings } from 'redux/api/dashboard-api';
import { setSettings } from 'redux/actions/dashboard-actions';
import { apiPutSettings } from 'redux/api/dashboard-api';
import { DELETE_FAVOURITE } from 'redux/constants/dashboard-constants';
import { ADD_FAVOURITE } from 'redux/constants/dashboard-constants';
import { FETCH_FAVOURITES } from 'redux/constants/dashboard-constants';
import { FIND_CHARITIES } from 'redux/constants/dashboard-constants';
import { apiDeleteFavourite } from 'redux/api/dashboard-api';
import { apiAddFavourite } from 'redux/api/dashboard-api';
import { apiFetchFavourites } from 'redux/api/dashboard-api';
import { apiFindCharities } from 'redux/api/dashboard-api';
import { setFavourites } from 'redux/actions/dashboard-actions';
import { FETCH_CATEGORIES } from 'redux/constants/dashboard-constants';
import { apiFetchCategories } from 'redux/api/dashboard-api';
import { setCategories } from 'redux/actions/dashboard-actions';
import { setCharities } from 'redux/actions/dashboard-actions';
import { fetchFavourites } from 'redux/actions/dashboard-actions';
import i18n from 'i18n';
import { UPLOAD_LOGO } from 'redux/constants/dashboard-constants';
import { apiUploadLogo } from 'redux/api/dashboard-api';
import { UPLOAD_DZI } from 'redux/constants/dashboard-constants';
import { apiUploadDzi } from 'redux/api/dashboard-api';
import { FETCH_BANK_ACCOUNTS } from 'redux/constants/dashboard-constants';
import { setBankAccounts } from 'redux/actions/dashboard-actions';
import { apiGetBankAccounts } from 'redux/api/dashboard-api';
import { apiPutBankAccount } from 'redux/api/dashboard-api';
import { UPDATE_BANK_ACCOUNT } from 'redux/constants/dashboard-constants';
import { apiPostBankAccount } from 'redux/api/dashboard-api';
import { fetchAccount as fetchAccountAction } from 'redux/actions/dashboard-actions';
import { setUploadedFileUrl } from 'redux/actions/global-actions';
import { setPlatforms } from 'redux/actions/dashboard-actions';
import { apiFetchPlatforms } from 'redux/api/dashboard-api';
import { FETCH_PLATFORMS } from 'redux/constants/dashboard-constants';
import { apiGetDirectDebitSetupIntent } from 'redux/api/dashboard-api';
import { setSetupIntent } from 'redux/actions/dashboard-actions';
import { GET_DIRECTDEBIT_SETUP_INTENT } from 'redux/constants/dashboard-constants';
import { apiProofUpload } from 'redux/api/dashboard-api';
import { UPLOAD_PROOF } from 'redux/constants/dashboard-constants';
import { FETCH_CHARITY_VIS_RULES } from 'redux/constants/dashboard-constants';
import { ADD_CHARITY_VIS_RULE } from 'redux/constants/dashboard-constants';
import { DELETE_CHARITY_VIS_RULE } from 'redux/constants/dashboard-constants';
import { apiGetCharityVisRules } from 'redux/api/dashboard-api';
import { setCharityVisRules } from 'redux/actions/dashboard-actions';
import { apiPostCharityVisRule } from 'redux/api/dashboard-api';
import { apiDeleteCharityVisRule } from 'redux/api/dashboard-api';
import { fetchCharityVisRules } from 'redux/actions/dashboard-actions';
import { setAdditionalCategories } from 'redux/actions/dashboard-actions';

function* fetchAccount() {
    try {
        yield put(setLoading());
        const result = yield call(apiGetAccount);
        yield put(setAccount(result));
        yield put(finishLoading());
    } catch (e) {
        console.error("Error occured: " + e);
        //error handling
    }
}

function* updateAccount({ payload }) {
    try {
        yield put(setLoading());
        yield put(setAccount(payload));
        yield call(apiPutAccount, payload);
        yield put(setSuccessMessage(i18n.t("Account data successfully updated")));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred while saving")));
        console.error(e);
        //error handling
    }
}

function* fetchSettings() {
    try {
        yield put(setLoading());
        const result = yield call(apiGetSettings);
        yield put(setSettings(result));
        yield put(finishLoading());
    } catch (e) {
        console.error("Error occured: " + e);
        //error handling
    }
}

function* updateSettings({ payload }) {
    try {
        yield put(setLoading());
        yield put(setSettings(payload));
        yield call(apiPutSettings, payload);
        yield put(setSuccessMessage(i18n.t("Settings successfully updated")));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred while saving")));
        console.error(e);
        //error handling
    }
}

function* deleteFavourite({ payload }) {
    try {
        yield put(setLoading());
        yield call(apiDeleteFavourite, payload);
        // yield put(setSuccessMessage("Charity successfully deleted"));
        const state = yield select();
        // console.log("State: " + JSON.stringify(state));
        // console.log("Before: " + JSON.stringify(state.dashboard.favourites));
        // console.log("after: " + JSON.stringify());
        yield put(setFavourites(state.dashboard.favourites.filter(f => f.id !== payload)));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred while deleting")));
        console.error(e);
    }
}

function* addFavourite({ payload }) {
    try {
        yield put(setLoading());
        yield call(apiAddFavourite, payload);
        // yield put(setSuccessMessage("Charity successfully added"));
        yield put(fetchFavourites());
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred while saving")));
        console.error(e);
    }
}

function* actionFetchFavourites() {
    try {
        yield put(setLoading());
        const result = yield call(apiFetchFavourites);
        yield put(setFavourites(result));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred")));
        console.error(e);
    }
}

function* findCharities({ payload }) {
    try {
        yield put(setLoading());
        const state = yield select();
        const currentAccountId = state.dashboard.account.id;
        const result = yield call(apiFindCharities, { queryObj: payload.queryObj, currentAccountId });
        // TODO: fill favourites into search suggestion
        yield put(setCharities(result));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred")));
        console.error(e);
    }
}

function* fetchCategories({ payload }) {
    try {
        yield put(setLoading());
        const result = yield call(apiFetchCategories, payload);
        if (payload && payload.length > 0)
            yield put(setAdditionalCategories(result));
        else
            yield put(setCategories(result));
        yield put(finishLoading());
    }
    catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred while fetching categories")));
        console.error(e);
    }
}

function* uploadLogo({ payload }) {
    try {
        yield put(setLoading());
        if (payload.file.size > 2097152) {
            yield put(setErrorMessage(i18n.t("File is too large, maximum size is 2MB")));
            yield put(finishLoading());
            return;
        }
        const logoUrl = yield call(apiUploadLogo, payload);
        yield put(setUploadedFileUrl({ url: logoUrl, field: "logo_url" }));
        yield put(setSuccessMessage(i18n.t("Logo uploaded successfully.")));
        yield put(finishLoading());
    }
    catch (e) {
        if (e.response && e.response.data && e.response.data.message === "extension_error")
            yield put(setErrorMessage(i18n.t("The selected file type is incorrect")));
        else
            yield put(setErrorMessage(i18n.t("Error occurred during image upload")));
        console.error(e);
    }
}

function* uploadDzi({ payload }) {
    try {
        yield put(setLoading());
        if (payload.file.size > 2097152) {
            yield put(setErrorMessage(i18n.t("File is too large, maximum size is 2MB")));
            yield put(finishLoading());
            return;
        }
        const dziUrl = yield call(apiUploadDzi, payload);
        yield put(setUploadedFileUrl({ url: dziUrl, field: "dzi_url" }));
        // yield put(fetchAccountAction());
        yield put(setSuccessMessage(i18n.t("DZI Certificate uploaded successfully.")));
        yield put(finishLoading());
    }
    catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred during the upload of DZI Certificate")));
        console.error(e);
    }
}

function* uploadProof({ payload }) {
    try {
        yield put(setLoading());
        if (payload.file.size > 2097152) {
            yield put(setErrorMessage(i18n.t("File is too large, maximum size is 2MB")));
            yield put(finishLoading());
            return;
        }
        const proofUrl = yield call(apiProofUpload, payload);
        yield put(setUploadedFileUrl({ url: proofUrl, field: "proof_url" }));
        // yield put(fetchAccountAction());
        yield put(setSuccessMessage(i18n.t("Charity Proof Certificate uploaded successfully.")));
        yield put(finishLoading());
    }
    catch (e) {
        if (e.response && e.response.data && e.response.data.message === "extension_error")
            yield put(setErrorMessage(i18n.t("The selected file type is incorrect, allowed types are pdf, jpg, jpeg")));
        else
            yield put(setErrorMessage(i18n.t("Error occurred during the upload of Charity Proof Certificate")));
        console.error(e);
    }
}

function* fetchBankAccounts() {
    try {
        yield put(setLoading());
        const result = yield call(apiGetBankAccounts);
        let output;
        if (result[0] && result[0].object) {
            // Means we are dealing with a stripe object
            output = {};
            output.accountholder_name = result[0]["billing_details"]["name"];
            output.accountholder_email = result[0]["billing_details"]["email"];
            output.last4 = result[0]["sepa_debit"]["last4"];
            output.id = result[0].id;
        }
        // yield put(setAccount(result));
        yield put(setBankAccounts(output ? [output] : result));
        yield put(finishLoading());
    } catch (e) {
        console.error("Error occured: " + e);
        //error handling
    }
}

function* updateBankAccount({ payload }) {
    try {
        yield put(setLoading());
        if (!payload.id)
            yield call(apiPostBankAccount, payload);
        else
            yield call(apiPutBankAccount, payload);
        yield put(setSuccessMessage(i18n.t("Bank account successfully updated.")));
        // yield put(setAccount(result));
        // yield put(setBankAccounts(result));
        yield put(finishLoading());
    } catch (e) {
        console.error("Error occured: " + e);
        yield put(setErrorMessage(i18n.t("Error while updating bank account")));
        //error handling
    }
}

function* fetchPlatforms() {
    try {
        yield put(setLoading());
        const platforms = yield call(apiFetchPlatforms);
        yield put(setPlatforms(platforms));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred")));
    }
}

function* getDirectDebitSetupIntent() {
    try {
        yield put(setLoading());
        const intent = yield call(apiGetDirectDebitSetupIntent);
        yield put(setSetupIntent(intent));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred")));
    }
}

function* getCharityVisibilityRules({ payload }) {
    try {
        yield put(setLoading());
        const rules = yield call(apiGetCharityVisRules, payload);
        if (!payload.queryObj) {
            payload.queryObj = {};
            payload.queryObj.page = 1;
            payload.queryObj.sizePerPage = 25;
            payload.queryObj.totalSize = parseInt(rules.count);
        }
        yield put(setCharityVisRules({ rules: rules.data, queryObj: payload.queryObj }));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred")));
    }
}

function* addCharityVisibilityRule({ payload }) {
    try {
        const state = yield select();
        const charityId = payload.charityId;
        yield put(setLoading());
        yield call(apiPostCharityVisRule, payload);
        yield put(setSuccessMessage(i18n.t("New rule created successfully")));
        yield put(fetchCharityVisRules({ charityId, queryObj: state.dashboard.charityVisibilityRulesQueryObj }))
        yield put(finishLoading());
    } catch (e) {
        console.error(e);
        if (e.response && e.response.data && e.response.data.message === "duplicate") {
            yield put(setErrorMessage(i18n.t("You have already defined a rule for this subject")));
        }
        else
            yield put(setErrorMessage(i18n.t("Error occurred")));
    }
}

function* deleteCharityVisibilityRule({ payload }) {
    try {
        const state = yield select();
        const charityId = payload.charityId;
        yield put(setLoading());
        yield call(apiDeleteCharityVisRule, payload);
        yield put(setSuccessMessage(i18n.t("Rule deleted successfully")));
        yield put(fetchCharityVisRules({ charityId, queryObj: state.dashboard.charityVisibilityRulesQueryObj }))
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred")));
    }
}

export default function* dashboardSaga() {
    yield takeEvery(FETCH_ACCOUNT, fetchAccount);
    yield takeLatest(UPDATE_ACCOUNT, updateAccount);
    yield takeEvery(FETCH_SETTINGS, fetchSettings);
    yield takeLatest(UPDATE_SETTINGS, updateSettings);
    yield takeEvery(DELETE_FAVOURITE, deleteFavourite);
    yield takeEvery(ADD_FAVOURITE, addFavourite);
    yield takeEvery(FETCH_FAVOURITES, actionFetchFavourites);
    yield takeEvery(FIND_CHARITIES, findCharities);
    yield takeLatest(FETCH_CATEGORIES, fetchCategories);
    yield takeLatest(UPLOAD_LOGO, uploadLogo);
    yield takeLatest(UPLOAD_DZI, uploadDzi);
    yield takeLatest(FETCH_BANK_ACCOUNTS, fetchBankAccounts);
    yield takeLatest(UPDATE_BANK_ACCOUNT, updateBankAccount);
    yield takeLatest(FETCH_PLATFORMS, fetchPlatforms);
    yield takeLatest(GET_DIRECTDEBIT_SETUP_INTENT, getDirectDebitSetupIntent);
    yield takeLatest(UPLOAD_PROOF, uploadProof);
    yield takeLatest(FETCH_CHARITY_VIS_RULES, getCharityVisibilityRules);
    yield takeLatest(ADD_CHARITY_VIS_RULE, addCharityVisibilityRule);
    yield takeLatest(DELETE_CHARITY_VIS_RULE, deleteCharityVisibilityRule)
}