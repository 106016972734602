/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert
} from "reactstrap";
import { connect } from "react-redux";
import { authFailed } from "redux/actions/authentication-actions";
import { clearRedirect } from "redux/actions/global-actions";
import { authSuccess } from "redux/actions/authentication-actions";
import { forgotPassword } from "redux/actions/authentication-actions";
import { confirmForgotPassword } from "redux/actions/authentication-actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      repeat_password: "",
      code: ""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate() {
    if (this.props.redirectUrl.length > 0) {
      this.props.history.push(this.props.redirectUrl);
      this.props.clearRedirect();
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.props.forgotPassword(this.state.email);
    // alert("email: " + this.state.email + ". Pass: " + this.state.password);
    // this.props.
  }

  handleConfirmSubmit(evt) {
    evt.preventDefault();
    if (this.state.password !== this.state.repeat_password) {
      this.props.authFailed(this.props.t("The entered passwords do not match"));
      return;
    }
    else if (this.state.password.length < 8) {
      this.props.authFailed(this.props.t("The minimum length of password is 8 characters"));
      return;
    }
    this.props.confirmForgotPassword({
      email: this.state.email,
      password: this.state.password,
      code: this.state.code
    });
    // alert("email: " + this.state.email + ". Pass: " + this.state.password);
    // this.props.
  }

  onChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    });
    this.props.authFailed('');
    this.props.authSuccess('');
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              {
                this.props.forgotPasswordPending ?
                  <>
                    <div className="text-center text-muted mb-4">
                      <h2>{this.props.t("Enter the code sent to your e-mail along with your new password.")}</h2>
                    </div>
                    <Form role="form" onSubmit={this.handleConfirmSubmit}>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input required placeholder={this.props.t("E-mail address")} type="email" name="email" autoComplete="new-email" onChange={this.onChange} />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input required placeholder={this.props.t("Password")} type="password" name="password" autoComplete="new-password" onChange={this.onChange} />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input required placeholder={this.props.t("Repeat password")} type="password" name="repeat_password" autoComplete="new-repeat-password" onChange={this.onChange} />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i class="fas fa-user-secret"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input required placeholder={this.props.t("Verification code")} type="number" name="code" autoComplete="new-code" onChange={this.onChange} />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button className="my-4" color="primary" type="submit">
                          {this.props.t("Send")}
                        </Button>
                      </div>
                    </Form>
                  </>
                  :
                  <>
                    <div className="text-center text-muted mb-4">
                      <h2>{this.props.t("Reset your password")}</h2>
                    </div>
                    <Form role="form" onSubmit={this.handleSubmit}>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input required placeholder={this.props.t("E-mail address")} type="email" name="email" autoComplete="new-email" onChange={this.onChange} />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button className="my-4" color="primary" type="submit">
                          {this.props.t("Send")}
                        </Button>
                      </div>
                    </Form>
                  </>
              }
              {
                (this.props.errorMessage && this.props.errorMessage.length > 0 ?
                  // <div className="mt-4">
                  <Alert color="danger"><strong>{this.props.errorMessage}</strong></Alert>
                  // </div> 
                  : null)
              }
              {
                (this.props.successMessage && this.props.successMessage.length > 0 ?
                  // <div className="mt-4">
                  <Alert color="success"><strong>{this.props.successMessage}</strong></Alert>
                  // </div> 
                  : null)
              }
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="auth/login"
              // onClick={e => e.preventDefault()}
              >
                <small>{this.props.t("Sign in")}</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
              // onClick={e => e.preventDefault()}
              >
                <small>{this.props.t("Create new account")}</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.authentication.errorMessage,
    successMessage: state.authentication.successMessage,
    redirectUrl: state.global.redirectUrl,
    forgotPasswordPending: state.authentication.forgotPasswordPending
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authFailed: err => dispatch(authFailed(err)),
    authSuccess: msg => dispatch(authSuccess(msg)),
    clearRedirect: () => dispatch(clearRedirect()),
    forgotPassword: email => dispatch(forgotPassword(email)),
    confirmForgotPassword: payload => dispatch(confirmForgotPassword(payload))
  };
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ForgotPassword);
