import React, { useState } from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

function InfoWithTooltip({ text, id }) {
    const [isOpen, setIsOpen] = useState(false);
    return (<>
        <i style={{ marginLeft: 4 }} id={id} className="fas fa-info-circle" onClick={() => setIsOpen(!isOpen)} />
        <UncontrolledPopover placement="bottom" target={id} className="popover-primary">
            <PopoverBody style={{ color: "black" }}>
                {text}
            </PopoverBody>
        </UncontrolledPopover>
    </>);
}

export default InfoWithTooltip;