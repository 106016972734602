/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert
} from "reactstrap";
import { connect } from "react-redux";
import { authFailed, authenticateUser } from "redux/actions/authentication-actions";
import { clearRedirect } from "redux/actions/global-actions";
import { authSuccess } from "redux/actions/authentication-actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { setRedirect } from "redux/actions/global-actions";
import { getUserId } from "redux/actions/authentication-actions";
import { logOut } from "redux/actions/authentication-actions";
import logo from 'assets/img/brand/Rest cent logo large.png';
import { Redirect } from "react-router-dom";

class EndUserLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loggingIn: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.AuthComponent = this.AuthComponent.bind(this);
  }

  componentDidMount() {
    if (!/shop=/.test(window.location.search))
      this.props.setRedirect("/auth/login")
    else
      try {
        let shopId, shopRedirect;
        if (/shop=/.test(window.location.search)) {
          shopId = window.location.search.match(/shop=.*/)[0].split("shop=")[1].split("&")[0];
          if (/redirect=/.test(window.location.search))
            shopRedirect = window.location.search.match(/redirect=.*/)[0].split("redirect=")[1].split("&")[0];
          this.props.fetchUserId({ shopId, shopRedirect });
        }
      } catch (e) {
        console.error(e);
      }
  }

  componentDidUpdate() {
    if (this.props.redirectUrl.length > 0) {
      this.props.history.push(this.props.redirectUrl);
      this.props.clearRedirect();
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if (this.state.password.length < 8)
      return this.props.authFailed(this.props.t("The minimum length of password is 8 characters"));
    this.setState({ loggingIn: true });
    this.props.authenticateUser(this.state.email, this.state.password, true);
    // alert("email: " + this.state.email + ". Pass: " + this.state.password);
    // this.props.
  }

  onChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    });
    this.props.authFailed('');
    this.props.authSuccess('');
  }

  AuthComponent() {
    if (!this.props.user || Object.keys(this.props.user).length === 0) {
      return (<>
        <div className="text-center text-muted mb-4">
          <h2>{this.props.t("Loading...")}</h2>
        </div>
      </>)
    }
    else if (this.props.user.error === "account_type_charity")
      return (<>
        <div className="text-center text-muted mb-4">
          <h2>{this.props.t("This E-mail address is assigned to a charity account, it cannot be used for private spending!")}</h2>
          <Button color="primary"><a style={{ color: "white" }} href={"//" + this.props.user.redirect}>{this.props.t("Back to shop")}</a></Button>
          <Button outline onClick={() => { this.props.logOut(); }}>{this.props.t("Switch account")}</Button>
        </div>
      </>
      )
    else if (this.props.user.error === "account_type_shop")
      return (<>
        <div className="text-center text-muted mb-4">
          <h2>{this.props.t("This E-mail address is assigned to a shop account, it cannot be used for private spending!")}</h2>
          <Button color="primary"><a style={{ color: "white" }} href={"//" + this.props.user.redirect}>{this.props.t("Back to shop")}</a></Button>
          <Button outline onClick={() => { this.props.logOut(); }}>{this.props.t("Switch account")}</Button>
        </div>
      </>
      )
    else if (this.props.user.error === "incorrect_id" || this.props.user.error === "incorrect_data")
      return (<>
        <div className="text-center text-muted mb-4">
          <h2>{this.props.t("Something went wrong, the link you have entered is incorrect.")}</h2>
        </div>
      </>
      )
    else if (this.props.user.id)
      return (this.state.loggingIn ?
        <>
          <Redirect to={this.props.user.redirect} />
        </>
        : <>
          <div className="text-center text-muted mb-4">
            <h3>{this.props.t("You are logged in with {{email}}", { email: this.props.user.email })}</h3>
            <h5>{this.props.t("Do you wish to use your favourite charities while shopping with {{shop}}", { shop: this.props.user.shop_name })}</h5>
            <Button color="success"><a style={{ color: "white" }} href={"//" + this.props.user.redirect}>{this.props.t("Confirm")}</a></Button>
            <Button color="danger"><a style={{ color: "white" }} href={"//" + this.props.user.redirect.split("?")[0]}>{this.props.t("Cancel")}</a></Button>
          </div>
          <div className="text-center text-muted mb-4">
            <Button onClick={() => { this.props.logOut(); }} outline size="sm">{this.props.t("Switch account")}</Button>
          </div>
        </>);
    else
      return (<>
        <Form role="form" onSubmit={this.handleSubmit}>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input required placeholder={this.props.t("E-mail address")} type="email" name="email" autoComplete="new-email" onChange={this.onChange} />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <Input required placeholder={this.props.t("Password")} type="password" autoComplete="new-password" name="password" onChange={this.onChange} />
            </InputGroup>
          </FormGroup>
          <small>{this.props.t("After successful authentication, you will be redirected back to the shop to complete the purchase.")}</small>
          <div className="text-center">
            <Button className="my-4" color="primary" type="submit">
              {this.props.t("Sign in")}
            </Button>
          </div>
        </Form>
      </>)
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <img
              className="img-center"
              style={{ maxWidth: "50%", marginTop: 32 }}
              alt="..."
              src={logo}
            />
            <CardBody className="px-lg-5 py-lg-5">
              <this.AuthComponent />
              {
                (this.props.errorMessage && this.props.errorMessage.length > 0 ?
                  // <div className="mt-4">
                  <Alert color="danger"><strong>{this.props.errorMessage}</strong></Alert>
                  // </div> 
                  : null)
              }
              {
                (this.props.successMessage && this.props.successMessage.length > 0 ?
                  // <div className="mt-4">
                  <Alert color="success"><strong>{this.props.successMessage}</strong></Alert>
                  // </div> 
                  : null)
              }
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="/auth/forgot"
                target="_blank"
              // onClick={e => e.preventDefault()}
              >
                <small>{this.props.t("Forgot password?")}</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
                target="_blank"
              // onClick={e => e.preventDefault()}
              >
                <small>{this.props.t("Create new account")}</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.authentication.errorMessage,
    successMessage: state.authentication.successMessage,
    redirectUrl: state.global.redirectUrl,
    account: state.dashboard.account,
    user: state.authentication.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserId: shop => dispatch(getUserId(shop)),
    clearRedirect: () => dispatch(clearRedirect()),
    authFailed: err => dispatch(authFailed(err)),
    authSuccess: msg => dispatch(authSuccess(msg)),
    setRedirect: url => dispatch(setRedirect(url)),
    authenticateUser: (email, password, same_page_refresh) => dispatch(authenticateUser({ email, password, same_page_refresh })),
    logOut: () => dispatch(logOut())
  };
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(EndUserLogin);
