import { FETCH_ACCOUNT } from "redux/constants/dashboard-constants";
import { SET_ACCOUNT } from "redux/constants/dashboard-constants";
import { FETCH_SETTINGS } from "redux/constants/dashboard-constants";
import { SET_SETTINGS } from "redux/constants/dashboard-constants";
import { UPDATE_SETTINGS } from "redux/constants/dashboard-constants";
import { FETCH_FAVOURITES } from "redux/constants/dashboard-constants";
import { DELETE_FAVOURITE } from "redux/constants/dashboard-constants";
import { ADD_FAVOURITE } from "redux/constants/dashboard-constants";
import { FETCH_PLATFORMS } from "redux/constants/dashboard-constants";
import { GET_DIRECTDEBIT_SETUP_INTENT } from "redux/constants/dashboard-constants";
import { UPLOAD_PROOF } from "redux/constants/dashboard-constants";
import { ADD_CHARITY_VIS_RULE } from "redux/constants/dashboard-constants";
import { SET_CHARITY_VIS_RULES } from "redux/constants/dashboard-constants";
import { SET_ADDITIONAL_CATEGORIES } from "redux/constants/dashboard-constants";
import { DELETE_CHARITY_VIS_RULE } from "redux/constants/dashboard-constants";
import { FETCH_CHARITY_VIS_RULES } from "redux/constants/dashboard-constants";
import { SET_DIRECTDEBIT_SETUP_INTENT } from "redux/constants/dashboard-constants";
import { SET_PLATFORMS } from "redux/constants/dashboard-constants";
import { FIND_CHARITIES } from "redux/constants/dashboard-constants";
import { SET_FAVOURITES } from "redux/constants/dashboard-constants";
import { FETCH_CATEGORIES } from "redux/constants/dashboard-constants";
import { SET_CATEGORIES } from "redux/constants/dashboard-constants";
import { SET_CHARITIES } from "redux/constants/dashboard-constants";
import { UPLOAD_LOGO } from "redux/constants/dashboard-constants";
import { UPLOAD_DZI } from "redux/constants/dashboard-constants";
import { FETCH_BANK_ACCOUNTS } from "redux/constants/dashboard-constants";
import { SET_BANK_ACCOUNTS } from "redux/constants/dashboard-constants";
import { UPDATE_BANK_ACCOUNT } from "redux/constants/dashboard-constants";

export function fetchAccount() {
    return { type: FETCH_ACCOUNT };
}
export function setAccount(payload) {
    return { type: SET_ACCOUNT, payload }
}
export function fetchSettings() {
    return { type: FETCH_SETTINGS };
}
export function setSettings(payload) {
    return { type: SET_SETTINGS, payload }
}
export function updateSettings(payload) {
    return { type: UPDATE_SETTINGS, payload }
}
export function fetchFavourites() {
    return { type: FETCH_FAVOURITES }
}
export function deleteFavourite(payload) {
    return { type: DELETE_FAVOURITE, payload }
}
export function addFavourite(payload) {
    return { type: ADD_FAVOURITE, payload }
}
export function findCharities(payload) {
    return { type: FIND_CHARITIES, payload }
}
export function setCharities(payload) {
    return { type: SET_CHARITIES, payload }
}
export function setFavourites(payload) {
    return { type: SET_FAVOURITES, payload }
}
export function fetchCategories(payload) {
    return { type: FETCH_CATEGORIES, payload };
}
export function setCategories(payload) {
    return { type: SET_CATEGORIES, payload }
}
export function uploadLogo(payload) {
    return { type: UPLOAD_LOGO, payload }
}
export function uploadDzi(payload) {
    return { type: UPLOAD_DZI, payload }
}
export function fetchBankAccounts() {
    return { type: FETCH_BANK_ACCOUNTS }
}
export function setBankAccounts(payload) {
    return { type: SET_BANK_ACCOUNTS, payload }
}
export function updateBankAccount(payload) {
    return { type: UPDATE_BANK_ACCOUNT, payload }
}
export function fetchPlatforms() {
    return { type: FETCH_PLATFORMS }
}
export function setPlatforms(payload) {
    return { type: SET_PLATFORMS, payload }
}
export function getSetupIntent() {
    return { type: GET_DIRECTDEBIT_SETUP_INTENT }
}
export function setSetupIntent(payload) {
    return { type: SET_DIRECTDEBIT_SETUP_INTENT, payload }
}
export function uploadProof(payload) {
    return { type: UPLOAD_PROOF, payload }
}
export function fetchCharityVisRules(payload) {
    return { type: FETCH_CHARITY_VIS_RULES, payload }
}
export function addCharityVisRule(payload) {
    return { type: ADD_CHARITY_VIS_RULE, payload }
}
export function deleteCharityVisRule(payload) {
    return { type: DELETE_CHARITY_VIS_RULE, payload }
}
export function setCharityVisRules(payload) {
    return { type: SET_CHARITY_VIS_RULES, payload }
}
export function setAdditionalCategories(payload) {
    return { type: SET_ADDITIONAL_CATEGORIES, payload }
}