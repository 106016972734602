export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const LOGOUT = 'LOGOUT';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const SET_AWAITS_CODE = 'SET_AWAITS_CODE';
export const CLEAR_AWAITS_CODE = 'CLEAR_AWAITS_CODE';
export const RESEND_CODE = 'RESEND_CODE';
export const SET_SIGNEDIN = 'SET_SIGNEDIN';
export const SET_TYPE = 'SET_TYPE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_RESET_PENDING = 'SET_RESET_PENDING';
export const CONFIRM_FORGOT_PASSWORD = 'CONFIRM_FORGOT_PASSWORD';
export const SET_EMAIL = 'SET_EMAIL';
export const GET_USER_ID = 'GET_USER_ID';
export const SET_USER_ID_RESPONSE = 'SET_USER_ID_RESPONSE';