import i18n from "i18n";
import { call, put, takeLatest } from "redux-saga/effects";
import { finishLoading } from "redux/actions/global-actions";
import { setErrorMessage } from "redux/actions/global-actions";
import { setLoading } from "redux/actions/global-actions";
import { setShops } from "redux/actions/shop-entity-actions";
import { apiFetchShops } from "redux/api/shop-entity-api";
import { FETCH_SHOPS } from "redux/constants/shop-entity-constants";

function* fetchShops({ payload }) {
    try {
        yield put(setLoading());
        const shops = yield call(apiFetchShops, payload);
        yield put(setShops(shops));
        yield put(finishLoading());
    } catch (e) {
        yield put(setErrorMessage(i18n.t("Error occurred")));
    }
}

export default function* shopEntitySaga() {
    yield takeLatest(FETCH_SHOPS, fetchShops)
}