import React from 'react';
import { withTranslation } from "react-i18next";
import InfoWithTooltip from 'components/Small/InfoWithTooltip';

const { Col, FormGroup, Button } = require("reactstrap");
const { useState } = require("react");

function ProfileImage({ account, t, handleFileUpload }) {
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");

    const submitFile = () => {
        handleFileUpload(file, "logo");
    }

    const handleImageChange = evt => {
        evt.preventDefault();
        let reader = new FileReader();
        let file = evt.target.files[0];
        if (/image\//.test(file.type))
            reader.onloadend = () => {
                setFile(file);
                setPreviewUrl(reader.result);
            }
        reader.readAsDataURL(file);
    }

    const ImagePreview = () => {
        let url = previewUrl || account.logo_url;
        if (url && url.length > 0) {
            return (<><img style={{
                height: 100, width: 100, borderRadius: "100%", border: "1px solid"
            }
            } alt={t("Current logo")} src={url} />
                {previewUrl ? <Button color="success" onClick={submitFile}>Upload</Button> : null}</>);
        }
        return null;
    }

    return (<>
        <Col lg="4">
            <FormGroup>
                <label
                    className="form-control-label"
                    htmlFor="file-logo">
                    {t("Logo")}</label><InfoWithTooltip id="logo_upload_expl" text={t("Maximum file size is 2MB. Allowed file types are jpg, jpeg, png")} />
                <div className="custom-file">
                    <input type="file" accept="image/png, image/jpeg, image/jpg" className="custom-file-input" id="file-logo" name="logo_file" lang="en" onChange={handleImageChange}></input>
                    <label className="custom-file-label" htmlFor="customFileLang">{t("Select file")}</label>
                </div>
            </FormGroup>
        </Col>
        <Col lg="2" xs="4">
            <ImagePreview />
        </Col></>);
}

export default withTranslation()(ProfileImage);