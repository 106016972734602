import React, { useState } from 'react';
import { ToogleButton } from '../../Buttons/Buttons';
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import styled from 'styled-components';
import InfoWithTooltip from 'components/Small/InfoWithTooltip';

const DonationCertificate = ({t, settings, setDonationCertificate}) => {
    const [open, setOpen] = useState(false);
    
    const handleOnChangeOff = () => { 
        setDonationCertificate(false)
    }
    const handleOnChangeOn = () => {
        setOpen(true);
    }

    const text = `Hier kannst du aktivieren ob Rest-Cent automatisch jährliche Spendenbescheingungen für deinen
        Online Shop beantragen soll. In diesem Fall wird Rest-Cent alle Spendentransaktionen die Du als
        Shop gespendet hast (d.h. ohne die Spenden deiner Einkäufer), sowie deine hinterlegten
        Kontaktdaten an die jeweilige Organisationen übertragen für die Du gespendet hast. Diese Daten
        sind erforderlich um eine Spendenbescheinigung ordnungsgemäß ausstellen zu können. Du
        bekommst eine System Mitteilung wenn dieser Prozess gestartet wurde, dies findet je nach
        Organisation Ende Januar und Februar eines Jahres statt.`

    return (
        <div>
            <h6 className="heading-small text-muted mb-4">
                {t("Automatische Spendenbescheinigung")}
            </h6>
            <div className="pl-lg-4 d-flex align-items-center">
                <ToogleButton 
                    handleOnChangeOff={handleOnChangeOff} 
                    handleOnChangeOn={handleOnChangeOn}
                    active={settings.donation_certificate}
                    t={t}
                    btn1Title="inaktiv"
                    btn2Title="aktiv "
                />
                <InfoWithTooltip id="donation_certificate" text={t(text)} />
            </div>
            <Popover placement="bottom" target="btnOn" isOpen={open}>
               <Wrapper>
                    <CloseBtn 
                        onClick={() =>{ 
                            setOpen(false);
                        }}
                    >
                        <i className="fa fa-times" />
                    </CloseBtn>
                    <PopoverBody style={{ color: "black" }}>
                        {t(text)}
                        <div className="mt-3">
                            <BtnStyled
                                size="sm"
                                outline 
                                color="danger"
                                onClick={() =>{ 
                                    setOpen(false);
                                }}
                            >
                                {t("Abbrechen")}
                            </BtnStyled>
                            <BtnStyled 
                                outline 
                                size="sm" 
                                color="info"
                                onClick={() => { 
                                    setDonationCertificate(true)
                                    setOpen(false);
                                }}
                            >
                                {t("Bestätigen")}
                            </BtnStyled>
                        </div>
                    </PopoverBody>
                </Wrapper>
            </Popover>
        </div>
    )
}

export default DonationCertificate;

const Popover = styled(UncontrolledPopover)`
  .popover {
    max-width: 550px !important;
    @media only screen and (max-width: 728px) {
        max-width: 100% !important;
        transform: translate3d(0px, 225px, 0px) !important;
    }
  }
`
const Wrapper = styled.div`
  padding: 20px;
  position: relative;
`
const CloseBtn = styled.span`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
`
const BtnStyled = styled(Button)`
  box-shadow: none !important;
`
