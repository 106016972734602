import styled from 'styled-components';

export const BtnWrapper = styled.div`
    background: rgb(30 , 36 , 71 , .03);
    border-radius: 14px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const Button = styled.button`
    border: none;
    background: none;
    font-size: 14px;
    font-weight: 600;
    width: 80px;
    line-height: 28px;
    height: 28px;
    cursor: pointer;
    letter-spacing: 0.1px;
    color: rgb(30 , 36 , 71 , .5);
    display: flex;
    justify-content: center;
    align-items: center;
    /* &.active: {
        color: rgb(255 , 255 , 255 , .9);
        letter-spacing: 0.2px;
        background: #00c48c;
        border-radius: 14px;
        box-shadow: -3px 0 7px 3px rgba(0; 0; 0; 0.06);
    }; */
`