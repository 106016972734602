import axios from 'axios';

let baseURL = 'https://dev.server.rest-cent.de/';
//TODO : Replace this with reading of env
try {
    baseURL = require('./local').default;
} catch (e) { }
const instance = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json', withCredentials: true }
})

instance.interceptors.request.use(function (req) {
    return req;
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(function (res) {
    return res;
}, function (err) {
    if (err.response && err.response.data.statusCode === 401 && err.response.data.message === 'jwt expired' && !window.location.href.includes('redirect_login')) {
        localStorage.removeItem("sessionExpiry");
        localStorage.removeItem("accType");
        window.location.reload(false);
    }
    return Promise.reject(err);
});

export default instance;