/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { connect } from "react-redux";
import CharityProfile from "components/General/profile-components/Charity-profile";
import UserProfile from "components/General/profile-components/User-profile";
import ShopProfile from "components/General/profile-components/Shop-profile";
import CompanyProfile from "components/General/profile-components/Company-profile";
import { dashboardInitialState } from "redux/reducers/dashboard-reducers";
import { updateAccount } from "redux/actions/authentication-actions";
import { setSuccessMessage } from "redux/actions/global-actions";
import { setErrorMessage } from "redux/actions/global-actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
// import SuccessErrorMessage from "components/General/settings-components/SuccessErrorMessage";
import { uploadLogo } from "redux/actions/dashboard-actions";
import { uploadDzi } from "redux/actions/dashboard-actions";
import { uploadProof } from "redux/actions/dashboard-actions";
// import profileImage from 'assets/img/theme/team-4-800x800.jpg';

class Profile extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      account: dashboardInitialState.account,
      unsavedChangesExist: false
    };
    this.onChange = this.onChange.bind(this);
    this.renderProfile = this.renderProfile.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  componentDidMount() {
    this.setState({ account: this.assignInitialAccount(this.props.account) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      this.setState({
        account: this.assignInitialAccount(this.props.account)
      })
    }
    if (prevProps.uploadedFileUrl !== this.props.uploadedFileUrl && this.props.uploadedFileUrl.url.length > 0) {
      const currAcc = this.state.account;
      currAcc[this.props.uploadedFileUrl.field] = this.props.uploadedFileUrl.url;
      this.setState({
        account: currAcc
      })
    }
  }

  assignInitialAccount(objToAssign) {
    let obj = dashboardInitialState.account;
    for (const key in obj) {
      obj[key] = objToAssign[key]
    }
    return obj;
  }

  onChange(evt) {
    this.props.setSuccessMessage("");
    this.props.setErrorMessage("");
    const val = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    this.setState({ account: { ...this.state.account, [evt.target.name]: val }, unsavedChangesExist: true });
  }

  onSave(e) {
    e.preventDefault();
    this.props.setSuccessMessage("");
    this.props.setErrorMessage("");
    this.props.updateAccount(this.state.account);
    this.setState({ unsavedChangesExist: false });
  }

  handleFileUpload(file, type) {
    switch (type) {
      case "logo":
        this.props.uploadLogo(this.state.account, file)
        break;
      case "dzi":
        this.props.uploadDzi(this.state.account, file);
        break;
      case 'proof':
        this.props.uploadProof(this.state.account, file);
        break;
      default:
        return null;
    }
    // this.props.updateAccount({ ...form, id: this.state.account.id });
  }

  renderProfile() {
    switch (localStorage.getItem("accType")) {
      case "USER":
        return <UserProfile account={this.state.account} onChange={this.onChange} />
      case "CHARITY":
        return <CharityProfile account={this.state.account} onChange={this.onChange} handleFileUpload={this.handleFileUpload} categories={this.props.categories} visibility={this.props.visibility} unsavedChangesExist={this.state.unsavedChangesExist} />
      case "SHOP":
        return <ShopProfile account={this.state.account} onChange={this.onChange} handleFileUpload={this.handleFileUpload} categories={this.props.categories} platforms={this.props.platforms} />
      case "COMPANY":
        return <CompanyProfile account={this.state.account} onChange={this.onChange} handleFileUpload={this.handleFileUpload} categories={this.props.categories} platforms={this.props.platforms} />
      default:
        return null;
    }
  }

  render() {
    return (
      <>
        <UserHeader headerType="profile" />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {/* <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                {/* <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={profileImage}
                        />
                      </a>
                    </div>
                  </Col>
                </Row> */}
            {/* <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between"> */}
            {/* <Button
                      className="mr-4"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Connect
                    </Button>
                    <Button
                      className="float-right"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      size="sm"
                    >
                      Message
                    </Button> */}
            {/* </div> */}
            {/* </CardHeader> */}
            {/* <CardBody className="pt-0 pt-md-4">
              <Row>
                <div className="col">
                  <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                    <div>
                      <span className="heading">22</span>
                      <span className="description">Friends</span>
                    </div>
                    <div>
                      <span className="heading">10</span>
                      <span className="description">Photos</span>
                    </div>
                    <div>
                      <span className="heading">89</span>
                      <span className="description">Comments</span>
                    </div>
                  </div>
                </div>
              </Row>
              <div className="text-center">
                <h3>
                  {this.state.}
                  <span className="font-weight-light">, 27</span>
                </h3>
                <div className="h5 font-weight-300">
                  <i className="ni location_pin mr-2" />
                      Bucharest, Romania
                    </div>
                <div className="h5 mt-4">
                  <i className="ni business_briefcase-24 mr-2" />
                      Solution Manager - Creative Tim Officer
                    </div>
                <div>
                  <i className="ni education_hat mr-2" />
                      University of Computer Science
                    </div>
                <hr className="my-4" />
                <p>
                  Ryan — the name taken by Melbourne-raised, Brooklyn-based
                  Nick Murphy — writes, performs and records all of his own
                  music.
                    </p>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  Show more
                    </a>
              </div>
            </CardBody>
              </Card>
            </Col> */}
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{this.props.t("My account")}</h3>
                    </Col>
                    {/* <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button>
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.onSave}>
                    {/* Address */}
                    {/* Account type-specific profile settings */}
                    {this.renderProfile()}
                    <div className="row justify-content-md-center">
                      <Button className="my-4" color="primary" type="submit">{this.props.t('Save')}</Button>
                    </div>
                  </Form>
                  {/* <div className="row justify-content-md-center">
                    <Col lg="6">
                      <SuccessErrorMessage />
                    </Col>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container >
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accType: state.authentication.accType,
    account: state.dashboard.account,
    successMessage: state.global.successMessage,
    errorMessage: state.global.errorMessage,
    categories: state.dashboard.categories,
    uploadedFileUrl: state.global.uploadedFileUrl,
    platforms: state.dashboard.platforms,
    visibility: state.dashboard.charityVisibilityRules,
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (account) => dispatch(updateAccount(account)),
    setSuccessMessage: (msg) => dispatch(setSuccessMessage(msg)),
    setErrorMessage: (msg) => dispatch(setErrorMessage(msg)),
    uploadLogo: (account, file) => dispatch(uploadLogo({ account, file })),
    uploadDzi: (account, file) => dispatch(uploadDzi({ account, file })),
    uploadProof: (account, file) => dispatch(uploadProof({ account, file }))
  };
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Profile);
