/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
// import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
// import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";
import Settings from "views/examples/Settings";
import TypeSelection from "views/examples/TypeSelection";
import ForgotPassword from "views/examples/ForgotPassword";
import { Trans } from 'react-i18next';
import EndUserLogin from 'views/examples/EndUserLogin';
import Donations from 'views/examples/Donations';

var routes = [
  // {
  //   path: "/index",
  //   name: <Trans>Dashboard</Trans>,
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/dashboard",
  //   showInSidebar: true,
  //   id: "dashboard"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/dashboard"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/dashboard"
  // },
  {
    path: "/user-profile",
    name: <Trans>Profile</Trans>,
    icon: "ni ni-shop text-blue",
    component: Profile,
    layout: "/dashboard",
    showInSidebar: true,
    id: "profile"
  }, {
    path: "/user-settings",
    name: <Trans>Settings</Trans>,
    icon: "ni ni-settings-gear-65 text-blue",
    component: Settings,
    layout: "/dashboard",
    showInSidebar: true,
    id: "settings"
  },
  {
    path: "/donations",
    name: <Trans>Donations</Trans>,
    icon: "ni ni-bullet-list-67 text-blue",
    component: Donations,
    showInSidebar: true,
    showForAccountTypes: ["USER", "SHOP", "COMPANY"],
    id: "donations",
    layout: "/dashboard"
  },
  {
    path: "/login",
    name: <Trans>Login</Trans>,
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/typeselection",
    name: <Trans>Account Type Selection</Trans>,
    icon: "ni ni-key-25 text-info",
    component: TypeSelection,
    protected: true,
    layout: "/auth"
  },
  {
    path: "/forgot",
    name: <Trans>Reset your password</Trans>,
    icon: "ni ni-key-25 text-info",
    component: ForgotPassword,
    layout: "/auth"
  },
  {
    path: "/register",
    name: <Trans>Register</Trans>,
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/redirect_login",
    name: <Trans>End User Login</Trans>,
    icon: "ni ni-key-25 text-info",
    component: EndUserLogin,
    layout: "/auth"
  },

];
export default routes;
