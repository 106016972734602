/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { connect } from "react-redux";
import { dashboardInitialState } from "redux/reducers/dashboard-reducers";
import { setSuccessMessage } from "redux/actions/global-actions";
import { setErrorMessage } from "redux/actions/global-actions";
import UserSettings from "components/General/settings-components/User-settings";
import CharitySettings from "components/General/settings-components/Charity-settings";
import ShopSettings from "components/General/settings-components/Shop-settings";
import CompanySettings from "components/General/settings-components/Company-settings";
import { updateSettings } from "redux/actions/dashboard-actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
// import SuccessErrorMessage from "components/General/settings-components/SuccessErrorMessage";

class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      settings: dashboardInitialState.settings
    };
    this.onChange = this.onChange.bind(this);
    this.handleChangeDonationCertificate = this.handleChangeDonationCertificate.bind(this);
    this.renderSettings = this.renderSettings.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.setState({ settings: this.assignInitialSettings(this.props.settings) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.settings !== this.props.settings && !prevProps.settings.id) {
      this.setState({
        settings: this.assignInitialSettings(this.props.settings)
      })
    }
  }

  assignInitialSettings(objToAssign) {
    let obj = dashboardInitialState.settings;
    if (!objToAssign)
      return null;
    for (let key in obj) {
      obj[key] = objToAssign[key]
    }
    return obj;
  }

  onChange(evt) {
    this.props.setSuccessMessage("");
    this.props.setErrorMessage("");
    const val = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    this.setState({ settings: { ...this.state.settings, [evt.target.name]: val } });
  }

  handleChangeDonationCertificate(updatedValue){
    this.setState({ settings: { ...this.state.settings, donation_certificate: updatedValue } });
  }

  onSave(e) {
    e.preventDefault();
    this.props.setSuccessMessage("");
    this.props.setErrorMessage("");
    this.props.updateSettings(this.state.settings);
  }

  renderSettings() {
    switch (localStorage.getItem("accType")) {
      case "USER":
        return <UserSettings settings={this.state.settings} onChange={this.onChange} />
      case "CHARITY":
        return <CharitySettings settings={this.state.settings} onChange={this.onChange} />
      case "SHOP":
        return <ShopSettings 
          settings={this.state.settings} 
          setDonationCertificate={this.handleChangeDonationCertificate}
          onChange={this.onChange} 
        />
      case "COMPANY":
        return <CompanySettings 
          settings={this.state.settings} 
          setDonationCertificate={this.handleChangeDonationCertificate}
          onChange={this.onChange} 
        />
      default:
        return null;
    }
  }

  render() {
    return (
      <>
        <UserHeader headerType="settings" />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{this.props.t("Account Settings")}</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.onSave}>

                    {/* Address */}
                    {/* Account type-specific profile settings */}
                    {this.renderSettings()}
                    <div className="row justify-content-md-center">
                      <Button className="my-4" color="primary" type="submit">
                        {this.props.t("Save")}
                      </Button>
                    </div>
                  </Form>
                  {/* <div className="row justify-content-md-center">
                    <Col lg="6">
                      <SuccessErrorMessage />
                    </Col>
                  </div> */}
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    {this.props.t("Login information")}
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            {this.props.t("E-mail address")}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder={this.props.t("E-mail address")}
                            value={this.props.account.contact_email}
                            name="contact_email"
                            onChange={this.onChange}
                            readOnly={true}
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-password"
                          >{this.props.t("Password")}</label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="xxxxxxxx"
                            id="input-password"
                            placeholder={this.props.t("Password")}
                            type="password" readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="row justify-content-md-center">
                      <Button className="my-4" color="primary" disabled >{this.props.t("Change login details")}</Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.dashboard.account,
    settings: state.dashboard.settings,
    bank_details: state.dashboard.bank_details,
    successMessage: state.global.successMessage,
    errorMessage: state.global.errorMessage
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSettings: (settings) => dispatch(updateSettings(settings)),
    setSuccessMessage: (msg) => dispatch(setSuccessMessage(msg)),
    setErrorMessage: (msg) => dispatch(setErrorMessage(msg))
  };
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Settings);
