import { prepareQueryUrl } from 'util/functions';
import axios from './axios-client';

export const apiGetAccount = async () => {
    const getAccountResult = await axios.get('auth/account');
    return getAccountResult.data.data;
}

export const apiPutAccount = async (account) => {
    const localAccount = Object.assign({}, account);
    const accountType = localStorage.getItem("accType");
    let path = '';
    switch (accountType) {
        case "USER":
            path = 'users/' + localAccount.id;
            break;
        case "CHARITY":
            path = 'charities/' + localAccount.id;
            break;
        case "SHOP":
            path = 'shops/' + localAccount.id;
            break;
        case "COMPANY":
            path = 'companies/' + localAccount.id;
            break;
        default:
            return null;
    }
    delete localAccount.id;
    delete localAccount.contact_email;
    const result = await axios.put(path, localAccount);
    return result.data;
}

export const apiGetSettings = async () => {
    const getAccount = await apiGetAccount();
    const account_id = getAccount.id;
    const accountType = localStorage.getItem("accType");
    let path = '';
    switch (accountType) {
        case "USER":
            path = 'users/' + account_id + '/settings';
            break;
        case "CHARITY":
            path = 'charities/' + account_id + "/settings";
            break;
        case "SHOP":
            path = 'shops/' + account_id + "/settings";
            break;
        case "COMPANY":
            path = 'companies/' + account_id + "/settings";
            break;
        default:
            return null;
    }
    const getSettingsResult = await axios.get(path);
    let result = getSettingsResult.data;
    result.account_id = account_id;
    return result;
}

export const apiPutSettings = async (settings) => {
    const localSettings = Object.assign({}, settings);
    const accountType = localStorage.getItem("accType");
    let path = '';
    switch (accountType) {
        case "USER":
            path = 'users/' + localSettings.account_id + "/settings";
            break;
        case "CHARITY":
            path = 'charities/' + localSettings.account_id + "/settings";
            break;
        case "SHOP":
            path = 'shops/' + localSettings.account_id + "/settings";
            break;
        case "COMPANY":
            path = 'companies/' + localSettings.account_id + "/settings";
            break;
        default:
            return null;
    }
    delete localSettings.id;
    delete localSettings.account_id;
    const result = await axios.put(path, localSettings);
    return result.data;
}

export const apiFetchFavourites = async () => {
    const result = await axios.get("favourites");
    return result.data;
}

export const apiFindCharities = async (payload) => {
    // const filter = payload.filter;
    const {queryObj, currentAccountId} = payload;

    // let path = "charities?";
    // if (filter)
    //     path += "search=" + filter;
    // if (localStorage.getItem("accType") === "SHOP") {
    //     const donator = payload.currentAccountId;
    //     if (donator)
    //         path += "&donator=" + donator;
    // }

    let path = "charities?";
    if (queryObj)
        path += queryObj.search ? "search=" + queryObj.search : "search=";
        path += queryObj.page ? ("&skip=" + ((queryObj.page - 1) * queryObj.sizePerPage)) : ""
        path += queryObj.sizePerPage ? ("&take=" + queryObj.sizePerPage) : "";
    if (localStorage.getItem("accType") === "SHOP") {
        const donator = currentAccountId;
        if (donator)
            path += "&donator=" + donator;
    }
    const result = await axios.get(path);
    //temp until dev/prd are synced, new structure of the response was introduced to also show total/count/skip
    return result.data.data || result.data;
}

export const apiAddFavourite = async (charity_id) => {
    const result = await axios.post("favourites", { charity: charity_id });
    return result.data;
}

export const apiDeleteFavourite = async (favourite_id) => {
    const result = await axios.delete("favourites/" + favourite_id);
    return result.data;
}

export const apiFetchCategories = async (type) => {
    const accountType = type || localStorage.getItem("accType");
    let path = '';
    switch (accountType) {
        case "CHARITY":
            path = 'charities/categories';
            break;
        case "SHOP":
            path = 'shops/categories';
            break;
        default:
            return [];
    }
    const result = await axios.get(path);
    return result.data;
}

export const apiFetchPlatforms = async () => {
    const result = await axios.get("shops/platforms");
    return result.data;
}

export const apiUploadLogo = async (payload) => {
    const accountType = localStorage.getItem("accType");
    let path = '';
    switch (accountType) {
        case "USER":
            path = 'users/' + payload.account.id + "/logo";
            break;
        case "CHARITY":
            path = 'charities/' + payload.account.id + "/logo";
            break;
        case "SHOP":
            path = 'shops/' + payload.account.id + "/logo";
            break;
        case "COMPANY":
            path = 'COMPANIES/' + payload.account.id + "/logo";
            break;
        default:
            return null;
    }
    const data = new FormData();
    data.append('file', payload.file);
    const result = await axios.post(path, data);
    return result.data;
}

export const apiUploadDzi = async (payload) => {
    const data = new FormData();
    data.append('file', payload.file);
    const result = await axios.post("charities/" + payload.account.id + "/dzi", data);
    return result.data;
}

export const apiGetBankAccounts = async () => {
    const result = await axios.get("/accounts");
    return result.data;
}

export const apiPutBankAccount = async (payload) => {
    const localBankAccount = Object.assign({}, payload);
    const bankAccountId = localBankAccount.id;
    delete localBankAccount.id;
    delete localBankAccount.account_name;
    delete localBankAccount.accountholder_country;
    const result = await axios.put("/accounts/" + bankAccountId, localBankAccount);
    return result.data;
}

export const apiPostBankAccount = async (payload) => {
    const result = await axios.post("/accounts", payload);
    return result.data;
}

export const apiGetDirectDebitSetupIntent = async () => {
    const result = await axios.post("/accounts/stripe_intent");
    return result.data;
}

export const apiProofUpload = async (payload) => {
    const data = new FormData();
    data.append('file', payload.file);
    const result = await axios.post("charities/" + payload.account.id + "/proof", data);
    return result.data;
}

export const apiGetCharityVisRules = async (payload) => {
    const result = await axios.get("charities/" + payload.charityId + "/visibility" + (prepareQueryUrl(payload.queryObj) || ''));
    return result.data;
}

export const apiPostCharityVisRule = async payload => {
    const charityId = payload.charityId;
    delete payload.charityId;
    const result = await axios.post("charities/" + charityId + "/visibility", payload.rule);
    return result.data;
}

export const apiDeleteCharityVisRule = async payload => {
    const result = await axios.delete("charities/" + payload.charityId + "/visibility/" + payload.ruleId);
    return result.data;
}