import React from 'react';
import { withTranslation } from "react-i18next";
import InfoWithTooltip from 'components/Small/InfoWithTooltip';
const { Col, FormGroup, Button } = require("reactstrap");
const { useState } = require("react");

function DocumentUploader({ t, handleFileUpload, type, getLabel }) {
    const [file, setFile] = useState(null);
    // const [previewUrl, setPreviewUrl] = useState("");

    const submitFile = () => {
        handleFileUpload(file, type);
    }

    const handleFileChange = evt => {
        console.error('erroring');
        evt.preventDefault();
        let reader = new FileReader();
        let file = evt.target.files[0];
        reader.onloadend = () => {
            setFile(file);
            // setPreviewUrl(reader.result);
        }
        reader.readAsDataURL(file);
    }

    // const ImagePreview = () => {
    //     let url = previewUrl || account.logo_url;
    //     if (url && url.length > 0) {
    //         return (<><img style={{
    //             width: "100%", borderRadius: "100%", border: "1px solid"
    //         }
    //         } alt={t("Current logo")} src={url} />
    //             {previewUrl ? <Button onClick={submitFile}>Upload</Button> : null}</>);
    //     }
    //     return null;
    // }

    return (<>
        <Col sm={file ? 4 : 6} xs="12" >
            <FormGroup>
                {getLabel ? getLabel() :
                    <>
                        <label
                            className="form-control-label"
                            htmlFor="file-logo">
                            {t("Document")}</label><InfoWithTooltip id="file_upload_expl" text={t("Maximum file size is 2MB")} />
                    </>
                }
                <div className="custom-file">
                    <input type="file" className="custom-file-input" id="file-document" name="dzi_file" lang="en" onChange={handleFileChange}></input>
                    <label className="custom-file-label" htmlFor="file-document">{file ? file.name : t("Select file")}</label>
                </div>
                {/* {file && <Button color="success" onClick={submitFile}>{t("Upload")}</Button>} */}
            </FormGroup>
        </Col>
        {file &&
            <Col sm="2" xs="12">
                <FormGroup><label htmlFor="confirmUploadButton" style={{ display: 'block' }} className="form-control-label">{t("Confirm")}</label>
                    <Button id="confirmUploadButton" color="success" onClick={submitFile}>{t("Upload")}</Button>
                </FormGroup>
            </Col>}
        {/* {account.dzi_url.length > 1 ?
            <Col lg="2" xs="4">
                <ImagePreview />
            </Col> : null} */}
    </>);
}

export default withTranslation()(DocumentUploader);