import { SET_ACCOUNT } from "redux/constants/dashboard-constants";
import { SET_SETTINGS } from "redux/constants/dashboard-constants";
import { SET_PLATFORMS } from "redux/constants/dashboard-constants";
import { SET_CHARITY_VIS_RULES } from "redux/constants/dashboard-constants";
import { SET_ADDITIONAL_CATEGORIES } from "redux/constants/dashboard-constants";
import { SET_DIRECTDEBIT_SETUP_INTENT } from "redux/constants/dashboard-constants";
import { SET_FAVOURITES } from "redux/constants/dashboard-constants";
import { SET_CATEGORIES } from "redux/constants/dashboard-constants";
import { SET_CHARITIES } from "redux/constants/dashboard-constants";
import { SET_BANK_ACCOUNTS } from "redux/constants/dashboard-constants";

export const dashboardInitialState = {
    account: {
        id: "",
        contact_name: "",
        contact_surname: "",
        contact_email: "",
        contact_phone: "",
        contact_plz: "",
        contact_street: "",
        contact_city: "",
        contact_country: "",
        about_html: "",
        website_url: "",
        shop_name: "",
        dzi_certificate: false,
        dzi_validity: "",
        dzi_url: "",
        dzi_external: false,
        name: "",
        mission_html: "",
        category: "",
        logo_url: "",
        public: false,
        platform: "",
        year_founded: 0,
        proof_url: "",
        visibility_approach: "",
        social_instagram_url: "",
        social_facebook_url: ""
    },
    settings: {
        account_id: "",
        newsletter: false,
        monthly_stats: false,
        donation_certificate: false,
        redirect_url: "",
        additional_donation: "",
        fix_monthly_donation: "",
        double_customer_donations: false,
        ereceipt_generation_consent: false,
        generate_receipts: false,
        is_active: false,
        auto_comm_subtract: false,
        commission_account: "MAIN"
    },
    bank_accounts: [{
        id: "",
        account_name: "",
        accountholder_name: "",
        accountholder_address: "",
        accountholder_postcode: "",
        accountholder_city: "",
        accountholder_country: "",
        accountholder_email: "",
        last4: "",
        iban: "",
        bic: "",
        bank_name: ""
    }],
    charities: [],
    favourites: [],
    categories: [],
    additionalCategories: [],
    platforms: [],
    charityVisibilityRules: [],
    charityVisibilityRulesQueryObj: {},
    logo: false,
    setupIntent: null
};

export default (state = dashboardInitialState, action) => {
    switch (action.type) {
        case SET_ACCOUNT:
            return Object.assign({}, state, {
                account: action.payload
            });
        case SET_SETTINGS:
            return Object.assign({}, state, {
                settings: action.payload
            });
        case SET_FAVOURITES:
            return Object.assign({}, state, {
                favourites: action.payload
            });
        case SET_CHARITIES:
            return Object.assign({}, state, {
                charities: action.payload
            });
        case SET_CATEGORIES:
            return Object.assign({}, state, {
                categories: action.payload
            });
        case SET_BANK_ACCOUNTS:
            return Object.assign({}, state, {
                bank_accounts: action.payload
            })
        case SET_PLATFORMS:
            return Object.assign({}, state, {
                platforms: action.payload
            })
        case SET_DIRECTDEBIT_SETUP_INTENT:
            return Object.assign({}, state, {
                setupIntent: action.payload
            })
        case SET_CHARITY_VIS_RULES:
            return Object.assign({}, state, {
                charityVisibilityRules: action.payload.rules,
                charityVisibilityRulesQueryObj: action.payload.queryObj
            })
        case SET_ADDITIONAL_CATEGORIES:
            return Object.assign({}, state, {
                additionalCategories: action.payload
            })
        default:
            return state;
    }
}