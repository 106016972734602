import React, { useState, useEffect } from 'react';
import { Button, Modal, CardBody, Card } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import { dashboardInitialState } from 'redux/reducers/dashboard-reducers';
// import AccountSettings from './Account-settings';
import { fetchBankAccounts, updateBankAccount } from 'redux/actions/dashboard-actions';
import StripeIbanForm from './StripeIbanForm';
import { getSetupIntent } from 'redux/actions/dashboard-actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { setSuccessMessage } from 'redux/actions/global-actions';
import { setErrorMessage } from 'redux/actions/global-actions';

function ShopBankSettings({ t, bank_accounts, fetchBankAccounts, setSuccessMessage, setErrorMessage, setupIntent, getSetupIntent }) {
    const [showData, setShowData] = useState(false);
    const stripePromise = loadStripe('pk_live_51I6h9WFzfX8XadAgqpG9lPaGBiAMVe9vbabdkkpenUawS4Gx380puou5WXVk4jtNl21MEUqCsNBR1kSSak3r9KG50041Ghg2ze', { locale: 'de' });
    useEffect(() => {
        if (showData) {
            fetchBankAccounts();
        }
    }, [showData, fetchBankAccounts]);

    return (<>
        <h6 className="heading-small text-muted mb-4">
            {t("Bank Details")}
        </h6>
        {showData ?
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={true}
                toggle={() => setShowData(!showData)}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <h3>{t("Bank Details")}</h3>
                            </div>
                            <Elements stripe={stripePromise} >
                                <StripeIbanForm t={t} setupIntent={setupIntent}
                                    getSetupIntent={getSetupIntent} setShowData={setShowData}
                                    setSuccessMessage={setSuccessMessage} setErrorMessage={setErrorMessage}
                                    account={bank_accounts[0]} />
                            </Elements>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            :
            <div className="text-center">
                <Button style={{ marginBottom: 24 }} outline color="warning" className="btn-icon btn-3" type="button" onClick={() => setShowData(true)} >
                    <i style={{ fontSize: "5em", margin: 24 }} className="fas fa-lock" ></i>
                </Button>
                <h2>{t("Your bank details are hidden")}</h2>
                <p className="lead">
                    {t("To protect your sensitive information, your bank details are hidden by default. If you wish to see or change them, click on the lock icon above")}
                </p>
            </div >
        }
    </>)
}
const mapStateToProps = state => {
    return {
        bank_accounts: state.dashboard.bank_accounts,
        setupIntent: state.dashboard.setupIntent
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchBankAccounts: () => dispatch(fetchBankAccounts()),
        updateBankAccount: bank_account => dispatch(updateBankAccount(bank_account)),
        getSetupIntent: () => dispatch(getSetupIntent()),
        setSuccessMessage: msg => dispatch(setSuccessMessage(msg)),
        setErrorMessage: msg => dispatch(setErrorMessage(msg))
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ShopBankSettings)