import React from 'react';
// import { Row, Col } from "reactstrap";
import FavouritesSelection from './Favourites-selection';
import SettingsSettings from './Settings-settings';
import { withTranslation } from 'react-i18next';
import ShopBankDetails from './ShopBankDetails';
import DonationCertificate from './DonationCertificate';
// import InfoWithTooltip from 'components/Small/InfoWithTooltip';

function CompanySettings({ t, onChange, settings, setDonationCertificate }) {

    return (<>
        {/* <SettingsSettings settings={settings} onChange={onChange} /> */}
        {/*<hr className="my-4" />
        <h6 className="heading-small text-muted mb-4">
            {t("Additional Donation options")}
        </h6>
        <div className="pl-lg-4">
            <Row>
                <Col lg="12">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="additional_donation"
                            type="checkbox"
                            checked={settings.additional_donation}
                            disabled
                            name="additional_donation"
                            onChange={onChange}
                        />
                        <label className="custom-control-label" htmlFor="additional_donation">{t("Additional donation")}</label><InfoWithTooltip id="additional_donation_info" text={t("Additional donation explanation")} />
                    </div>
                </Col>
                <Col lg="4">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-fixed-donation">
                            Fixed monthly donation
                        </label>
                        <Input
                            className="form-control-alternative"
                            id="input-fixed-donation"
                            placeholder="Amount in Euro"
                            onChange={onChange}
                            value={settings.fix_monthly_donation}
                            name="fix_monthly_donation"
                            type="number"
                        />
                    </FormGroup>
                </Col>
                <Col lg="4">
                    <label>OR</label>
                </Col>
                <Col lg="12">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="double_donations"
                            type="checkbox"
                            checked={settings.double_customer_donations}
                            name="double_customer_donations"
                            onChange={onChange}
                        />
                        <label className="custom-control-label" htmlFor="customCheck3">Double customer donations</label>
                    </div>
                </Col>
            </Row>
        </div> */}
        {/* <hr className="my-4" />
        <DonationCertificate 
            t={t} 
            settings={settings}
            setDonationCertificate={setDonationCertificate}
        /> */}
        {/* <hr className="my-4" /> */}
        <h6 className="heading-small text-muted mb-4">
            {t("Favourite Charities")}
        </h6>
        <div className="pl-lg-4">
            <FavouritesSelection />
        </div >
        <hr className="my-4" />
        {/* <h6 className="heading-small text-muted mb-4">
            {t("Other configurations")}
        </h6>
        <div className="pl-lg-4">
            <Row>
                <Col lg="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-redirect_url">{t("Redirect URL")}</label><InfoWithTooltip id="redirect_url_info" text={t("Shop Redirect URL explanation")} />
                        <Input
                            className="form-control-alternative"
                            id="input-redirect_url"
                            placeholder="https://..."
                            onChange={onChange}
                            value={settings.redirect_url}
                            name="redirect_url"
                            type="text" />
                    </FormGroup>
                </Col>
            </Row>
        </div > */}
        <ShopBankDetails bank_account={{}} settings={settings} t={t} />
        <hr className="my-4" />
    </>);
}
export default withTranslation()(CompanySettings);