/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert
} from "reactstrap";
import { connect } from "react-redux";
import { authFailed, authenticateUser, resendCode } from "redux/actions/authentication-actions";
import { clearRedirect } from "redux/actions/global-actions";
import { authSuccess } from "redux/actions/authentication-actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import logo from 'assets/img/brand/Rest cent logo large.png';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate() {
    if (this.props.redirectUrl.length > 0) {
      this.props.history.push(this.props.redirectUrl);
      this.props.clearRedirect();
    }
  }

  handleSubmit(evt) {
    evt.preventDefault();
    if (this.state.password.length < 8)
      return this.props.authFailed(this.props.t("The minimum length of password is 8 characters"));
    this.props.authenticateUser(this.state.email, this.state.password);
    // alert("email: " + this.state.email + ". Pass: " + this.state.password);
    // this.props.
  }

  onChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    });
    this.props.authFailed('');
    this.props.authSuccess('');
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <img
              className="img-center"
              style={{ maxWidth: "50%", marginTop: 32 }}
              alt="..."
              src={logo}
            />
            {/* <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/github.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader> */}
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h2>{this.props.t("Sign in with your credentials")}</h2>
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input required placeholder={this.props.t("E-mail address")} type="email" name="email" autoComplete="new-email" onChange={this.onChange} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input required placeholder={this.props.t("Password")} type="password" autoComplete="new-password" name="password" onChange={this.onChange} />
                  </InputGroup>
                </FormGroup>
                {/* <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div> */}
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    {this.props.t("Sign in")}
                  </Button>
                </div>
              </Form>
              {
                (this.props.errorMessage && this.props.errorMessage.length > 0 ?
                  // <div className="mt-4">
                  <Alert color="danger"><strong>{this.props.errorMessage}</strong></Alert>
                  // </div> 
                  : null)
              }
              {
                (this.props.successMessage && this.props.successMessage.length > 0 ?
                  // <div className="mt-4">
                  <Alert color="success"><strong>{this.props.successMessage}</strong></Alert>
                  // </div> 
                  : null)
              }
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="/auth/forgot"
              // onClick={e => e.preventDefault()}
              >
                <small>{this.props.t("Forgot password?")}</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
              // onClick={e => e.preventDefault()}
              >
                <small>{this.props.t("Create new account")}</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.authentication.errorMessage,
    successMessage: state.authentication.successMessage,
    redirectUrl: state.global.redirectUrl
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authFailed: err => dispatch(authFailed(err)),
    authSuccess: msg => dispatch(authSuccess(msg)),
    resendCode: email => dispatch(resendCode({ email })),
    clearRedirect: () => dispatch(clearRedirect()),
    authenticateUser: (email, password) => dispatch(authenticateUser({ email, password }))
  };
}


export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Login);
