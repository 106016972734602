import React from 'react';
import FavouritesSelection from './Favourites-selection';
import SettingsSettings from './Settings-settings';
import { withTranslation } from 'react-i18next';

function UserSettings({ t, onChange, settings }) {

    return (<>
        <SettingsSettings settings={settings} onChange={onChange} />
        <hr className="my-4" />
        <h6 className="heading-small text-muted mb-4">
            {t("Favourite Charities")}
        </h6>
        <div className="pl-lg-4">
            <FavouritesSelection />
        </div >
        {/* <hr className="my-4" />
        <h6 className="heading-small text-muted mb-4">
            Other configurations
        </h6>
        <div className="pl-lg-4">
            <Row>
                <Col lg="6">
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="privacy_level">Privacy level</label>
                        <Input
                            className="form-control-alternative"
                            id="privacy_level"
                            placeholder="https://..."
                            onChange={onChange}
                            value={settings.privacy_level}
                            name="redirect_url"
                            type="text" />
                    </FormGroup>
                </Col>
            </Row>
        </div > */}
    </>);
}
export default withTranslation()(UserSettings)