import React from "react";
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Button, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";

class CodeEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: ""
        }
        this.changeCode = this.changeCode.bind(this);
    }

    changeCode(evt) {
        this.setState({ code: evt.target.value });
    }

    render() {
        return (<div>
            <div className="text-center text-muted mb-4">
                <h2>{this.props.t("Enter the code sent to your e-mail.")}</h2>
            </div>
            <Form role="form" id="codeVerificationForm" onSubmit={(evt) => { evt.preventDefault(); this.props.onSubmit(this.state.code); }}>
                <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={this.props.t("Verification code")} type="code" autoComplete="new-code" name="code" onChange={this.changeCode} />
                    </InputGroup>
                </FormGroup>
                <Row className="justify-content-md-right">
                    <Col sm={{ size: 4, offset: 8 }}>
                        <Button color="secondary" size="sm" type="button" onClick={this.props.resendCode}>
                            {this.props.t("Resend code")}
                        </Button>
                    </Col>
                </Row>
                <div className="text-center">
                    <Button className="mt-4" color="primary" type="submit">
                        {this.props.t("Verify")}
                    </Button>
                </div>
            </Form>
        </div >)
    };
}

export default withTranslation()(CodeEntry);