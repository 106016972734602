import React, { useState } from 'react';
import { Input, Table, Media, Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { fetchShops } from 'redux/actions/shop-entity-actions';
import { setShops } from 'redux/actions/shop-entity-actions';
var timeout;

function debounce(func, wait, immediate) {
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


function CharityVisDonatorSearch({ t, fetchShops, shops, setShops, setObject, disabled }) {
    const [search, setSearch] = useState("");
    var debouncedSearch = debounce(function () {
        fetchShops({ search });
    }, 500);

    const onSearch = input => {
        setSearch(input);
        debouncedSearch();
        setShops([]);
    };
    return (<>
        <label
            className="form-control-label"
            htmlFor="search-shops">{t("Subject")}</label>
        <Input
            bsSize="sm"
            disabled={disabled}
            className="form-control-alternative"
            placeholder={t("Search by name")}
            key="search-shops"
            id="search_shops"
            onChange={evt => onSearch(evt.target.value)}
            onClick={() => onSearch(search)}
            value={search}
            name="search-shops"
            type="text" />
        <Popover placement="bottom" target="search_shops" isOpen={shops.length > 0} className="popover-container" modifiers={{ preventOverflow: { enabled: false }, flip: { enabled: false } }}>
            <PopoverBody>
                <PopoverHeader style={{ display: "flex", justifyContent: "space-between" }}>
                    {t("Search results")}
                    <Button size="sm" outline id="closePopOver" onClick={() => { setShops([]); }}><i className="fa fa-times" /></Button>
                </PopoverHeader>
                <Table className="align-items-center" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">{t("Shop")}</th>
                            <th scope="col">{t("Category")}</th>
                            <th scope="col">{t("Website")}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {shops.map(sho => {
                            return (
                                <tr key={sho.id}>
                                    <th scope="row">
                                        <Media className="align-items-center">
                                            <a
                                                className="avatar rounded-circle mr-3"
                                                href={"//" + sho.website_url}
                                                target="_blank"
                                                rel="noreferrer">
                                                <img
                                                    alt={sho.shop_name}
                                                    src={sho.logo_url} />
                                            </a>
                                            <Media>
                                                <span className="mb-0 text-sm">{sho.shop_name}</span>
                                            </Media>
                                        </Media>
                                    </th>
                                    <td>
                                        {sho.category}
                                    </td>
                                    <td>
                                        <a href={"//" + sho.website_url} target="_blank" rel="noreferrer">{sho.website_url}</a>
                                    </td>
                                    <td>
                                        <Button size="sm" color="primary" onClick={() => { setObject(sho.id); setSearch(sho.shop_name); setShops([]); }}>{t("Select")}</Button>
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>
            </PopoverBody>
        </Popover>
    </>)
}

const mapStateToProps = state => {
    return {
        shops: state.shops.shops
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchShops: queryObj => dispatch(fetchShops({ queryObj })),
        setShops: shops => dispatch(setShops(shops))
    }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CharityVisDonatorSearch);