import { SET_REDIRECT } from "redux/constants/global-constants";
import { CLEAR_REDIRECT } from "redux/constants/global-constants";
import { SET_SUCCESS_MESSAGE } from "redux/constants/global-constants";
import { SET_ERROR_MESSAGE } from "redux/constants/global-constants";
import { SET_UPLOADED_FILE_URL } from "redux/constants/global-constants";

const initialState = {
    loading: false,
    redirectUrl: "",
    successMessage: "",
    errorMessage: "",
    uploadedFileUrl: {
        url: "",
        field: ""
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_REDIRECT:
            return Object.assign({}, state, {
                redirectUrl: action.payload
            });
        case CLEAR_REDIRECT:
            return Object.assign({}, state, {
                redirectUrl: ""
            });
        case SET_SUCCESS_MESSAGE:
            return Object.assign({}, state, {
                successMessage: action.payload
            });
        case SET_ERROR_MESSAGE:
            return Object.assign({}, state, {
                errorMessage: action.payload
            });
        case SET_UPLOADED_FILE_URL:
            return Object.assign({}, state, {
                uploadedFileUrl: action.payload
            });
        default:

            break;
    }
    return state;
}